import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation, createSearchParams, useNavigate, useParams } from 'react-router-dom'
import MetaTags from 'react-meta-tags'
import { Card, CardBody, Col, Container, Row, Button, Form, FormGroup, Label, Input, FormText, FormFeedback } from 'reactstrap'
import toastr from 'toastr'
import { useForm } from 'react-hook-form'
import { APIClient } from '../../lib/apiHelper'

const ADMIN_ROLE = {
  name: '',
  ordering: 0,
  permission: [
    {
      resource: { name: '대시보드', code: 'dashboard' },
      authorities: [
        {
          name: '결제 현황',
          permission: 'paymentReport',
        },
        {
          name: '고객 문의현황',
          permission: 'serviceQuestionReport',
        },
      ],
    },
    {
      resource: { name: '회원관리', code: 'user' },
      authorities: [
        {
          name: '회원 리스트',
          permission: 'list',
        },
        {
          name: '회원 등록',
          permission: 'create',
        },
      ],
    },
    {
      resource: { name: '회사관리', code: 'company' },
      authorities: [
        {
          name: '회사 리스트',
          permission: 'list',
        },
        {
          name: '회사 등록',
          permission: 'create',
        },
      ],
    },
    {
      resource: { name: '백오피스 괸라', code: 'backoffice' },
      authorities: [
        {
          name: '운영자 리스트',
          permission: 'adminList',
        },
        {
          name: '운영자 등록',
          permission: 'adminCreate',
        },
        {
          name: '운영자 권한설정',
          permission: 'adminPermission',
        },
      ],
    },
    {
      resource: { name: '게시판', code: 'board' },
      authorities: [
        {
          name: '1:1 문의',
          permission: 'serviceQuestion',
        },
        {
          name: '공지사항',
          permission: 'notice',
        },
        {
          name: '패치&업그레이드',
          permission: 'patchAndUpgrade',
        },
        {
          name: '사용자 가이드 업로드',
          permission: 'userGuideUpload',
        },
      ],
    },
    {
      resource: { name: '결제', code: 'payment' },
      authorities: [
        {
          name: '결제 관리',
          permission: 'payment',
        },
        {
          name: '최소/환불 관리',
          permission: 'cancelReturn',
        },
        {
          name: '현금영수증 관리',
          permission: 'paymentReceipt',
        },
        {
          name: '세금계산서 관리',
          permission: 'tax',
        },
      ],
    },
    {
      resource: { name: '통계', code: 'analytics' },
      authorities: [
        {
          name: '방문자 분석',
          permission: 'traffic',
        },
        {
          name: '회원 분석',
          permission: 'user',
        },
        {
          name: '매출 분석',
          permission: 'sales',
        },
      ],
    },
  ],
}

const getFormOption = (register: any) => {
  let option: { [key: string]: any } = {
    name: register('name', { required: true }),
  }

  ADMIN_ROLE.permission.map((permission) => {
    option[permission.resource.code] = register(permission.resource.code, { required: false })

    permission.authorities.map((authority) => {
      option[`${permission.resource.code}:${authority.permission}`] = register(`${permission.resource.code}:${authority.permission}`, {
        required: false,
      })
    })
  })

  return option
}

interface PermissionFormProps {
  selectedAdminRole: any
  onSaveCallback: any
}

const PermissionForm = ({ selectedAdminRole, onSaveCallback }: PermissionFormProps) => {
  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm()

  useEffect(() => {
    populateAdminRole(selectedAdminRole)
  }, [selectedAdminRole])

  const clearForm = () => {
    const values: { [key: string]: any } = {
      name: '',
    }

    ADMIN_ROLE.permission.map((permission) => {
      values[`${permission.resource.code}`] = false
      permission.authorities.map((authority) => {
        values[`${permission.resource.code}:${authority.permission}`] = false
      })
    })

    reset(values)
  }

  const populateAdminRole = (data: any) => {
    if (!data) {
      //clear form
      clearForm()
    } else {
      const values: { [key: string]: any } = {
        name: data.name,
      }

      for (const permission of data.permission) {
        const temp = permission.split(':')
        const authorityCode = temp[0]
        const permissionCode = temp[1]

        values[permission] = true
      }

      console.log('populating value:', values)

      reset(values)
    }
  }

  const formOption = getFormOption(register)

  const onSubmit = async (data: any) => {
    let params = {
      name: data.name,
      permission: [] as any[],
      ordering: 0,
    }

    //setting permission data
    ADMIN_ROLE.permission.map((permission) => {
      permission.authorities.map((authority) => {
        const value = data[`${permission.resource.code}:${authority.permission}`]

        if (value) {
          //checked
          params.permission.push(`${permission.resource.code}:${authority.permission}`)
        }
      })
    })

    console.log('params:', params)

    if (!selectedAdminRole) {
      try {
        //create admin role
        const { data: result } = await APIClient.post('/adminRole', params)

        if (result.result) {
          toastr.success('권한이 등록 되었습니다')

          //clear form
          clearForm()

          //reload role list
          onSaveCallback()
        }
      } catch (error: any) {
        toastr.error(error.message)
      }
    } else {
      try {
        //update admin role
        const { data: result } = await APIClient.put(`/adminRole/${selectedAdminRole.id}`, params)

        if (result.result) {
          toastr.success('권한이 수정 되었습니다')

          //reload role list
          onSaveCallback()
        }
      } catch (error: any) {
        toastr.error(error.message)
      }
    }
  }

  const onError = (d: any) => {
    console.error('error', d)
  }

  const onResourceClickHandler = (e: any, permissionIndex: number) => {
    console.log('permissionIndex: ', permissionIndex, ' e.target.checked:', e.target.checked)

    const permission = ADMIN_ROLE.permission[permissionIndex]

    let values = getValues()

    console.log('currend form values:', values)

    for (const authority of permission.authorities) {
      const key = `${permission.resource.code}:${authority.permission}`
      setValue(key, e.target.checked)
    }
  }

  return (
    <>
      <Row>
        <Col xs="12">
          <Form onSubmit={handleSubmit(onSubmit, onError)}>
            <FormGroup row>
              <Label sm={2}>*권한명</Label>
              <Col sm={10}>
                <Input
                  name="name"
                  onChange={formOption.name.onChange}
                  onBlur={formOption.name.onBlur}
                  innerRef={formOption.name.ref}
                  invalid={!!errors.name}
                />
              </Col>
            </FormGroup>

            <div className="mt-3 mb-5">
              {ADMIN_ROLE.permission.map((permission, index: number) => {
                return (
                  <Row className={`${index % 2 === 1 ? 'bg-light' : ''}`} key={permission.resource.code}>
                    <Col xs="3">
                      <div className="d-flex align-items-baseline py-2">
                        <FormGroup check inline>
                          <Input
                            type="checkbox"
                            name={permission.resource.code}
                            onChange={(e) => {
                              formOption[permission.resource.code].onChange(e)
                              onResourceClickHandler(e, index)
                            }}
                            onBlur={formOption[permission.resource.code].onBlur}
                            innerRef={formOption[permission.resource.code].ref}
                            invalid={!!errors[permission.resource.code]}
                          />
                          <Label>{permission.resource.name}</Label>
                        </FormGroup>
                      </div>                      
                    </Col>
                    <Col xs="9">
                      <Row className="border-bottom border-secondary py-2">
                        {permission.authorities.map((authority) => {
                          return (
                            <Col xs="3" key={`${permission.resource.code}:${authority.permission}`}>
                              <FormGroup check inline>
                                <Input
                                  type="checkbox"
                                  name={`${permission.resource.code}:${authority.permission}`}
                                  onChange={formOption[`${permission.resource.code}:${authority.permission}`].onChange}
                                  onBlur={formOption[`${permission.resource.code}:${authority.permission}`].onBlur}
                                  innerRef={formOption[`${permission.resource.code}:${authority.permission}`].ref}
                                  invalid={!!errors[`${permission.resource.code}:${authority.permission}`]}
                                />
                                <Label>{authority.name}</Label>
                              </FormGroup>                              
                            </Col>
                          )
                        })}
                      </Row>
                    </Col>
                  </Row>
                )
              })}
            </div>

            <Row>
              <Col>
                <div className="d-flex gap-2 justify-content-end">
                  <Button
                    color="secondary"
                    onClick={() => {
                      clearForm()

                      onSaveCallback()
                    }}
                  >
                    최소
                  </Button>
                  <Button type="submit" color="primary">
                    저장
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </>
  )
}

export default PermissionForm
