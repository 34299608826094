import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation, createSearchParams, useNavigate } from 'react-router-dom'
import MetaTags from 'react-meta-tags'
import { Card, CardBody, Col, Container, Row, Button, Form, FormGroup, Label, Input, FormText, FormFeedback } from 'reactstrap'
import toastr from 'toastr'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import { useForm, Controller } from 'react-hook-form'
import Select from 'react-select'
import { APIClient } from '../../lib/apiHelper'
import SweetAlert from 'react-bootstrap-sweetalert'

const AdminUserAddForm = () => {
  const navigate = useNavigate()
  const {
    register,
    control,
    setValue,
    getValues,
    trigger,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const [state, setState] = useState({
    adminRoleList: [] as any[],
    validEmail: undefined,
  })
  const [alert, setAlert] = useState<any>(null)

  useEffect(() => {
    loadAdminRoleList()
  }, [])

  const loadAdminRoleList = async () => {
    try {
      const { data: result } = await APIClient.get('/adminRole')

      console.log('loadAdminRoleList:', result)

      const adminRoleList = result.data.adminRoleList.map((item: any) => {
        return {
          id: item.id,
          value: item.id,
          label: item.name,
        }
      })

      console.log('adminRoleList:', adminRoleList)

      if (result.result) {
        setState((prev: any) => {
          return {
            ...prev,
            adminRoleList,
          }
        })

        if (adminRoleList && adminRoleList.length > 0) {
          setTimeout(() => {
            setValue('adminRoleId', adminRoleList[0].id + '')
          }, 200)
        }
      }
    } catch (error: any) {
      toastr.error(error.message)
    }
  }

  const onSubmit = async (data: any) => {
    console.log('onSubmit', data)

    const params = {
      email: data.email,
      password: data.password,
      name: data.name,
      phone: data.phone,

      companyName: data.companyName,
      departName: data.departName,
      rankName: data.rankName,
      adminRoleId: data.adminRoleId,
    }

    try {
      const { data: result } = await APIClient.post(`/adminUser`, params)

      if (result.result) {
        toastr.success('운영자 등록 되었습니다')
        navigate('/adminUser')
      }
    } catch (error: any) {
      toastr.error(error.message)
    }
  }

  const onError = (d: any) => {
    console.error('error', d)
  }

  const validateEmail = () => {
    console.log('state.validEmail:', state.validEmail)

    if (state.validEmail === undefined) {
      return '이메일 조회해 주세요'
    } else if (state.validEmail === false) {
      return '사용 불가능한 이메일입니다'
    } else {
      return true
    }
  }

  const formOption = {
    email: register('email', {
      required: true,
      pattern: { value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, message: '유효한 이메일을 입력해주세요' },
      validate: validateEmail,
    }),
    name: register('name', { required: true, maxLength: { value: 15, message: '한글, 영문(최대15자)' } }),
    phone: register('phone', { required: true, maxLength: { value: 11, message: '최대 11자' } }),
    password: register('password', {
      required: true,
      pattern: {value: /^(?=.*\d)(?=.*[a-zA-Z]).{8,16}$/, message: '숫자, 영문 혼합 (최소8~최대 16자)'}
    }),

    companyName: register('companyName', { required: true, maxLength: { value: 15, message: '최대 15자' }}),
    departName: register('departName', { required: false, maxLength: { value: 15, message: '최대 15자' }}),
    rankName: register('rankName', { required: false, maxLength: { value: 15, message: '최대 15자' }}),
    adminRoleId: register('adminRoleId', { required: true }),
  }

  const checkEmail = async () => {
    console.log('checkEmail')

    try {
      const values = getValues()
      const email = values.email

      if (email) {
        const { data: result } = await APIClient.get(`/adminUser/searchId?email=${email}`)

        console.log('result:', result)

        if (result.result) {
          //make valid
          setState((prev: any) => {
            return {
              ...prev,
              validEmail: result.data.count === 0,
            }
          })

          trigger('email')
        }
      }
    } catch (error: any) {
      toastr.error(error.message)
    }
  }

  const onChangeEmail = () => {
    setState((prev: any) => {
      return {
        ...prev,
        validEmail: undefined
      }
    })
  }

  const cancel = () => {
    const getAlert = () => (
      <SweetAlert
        title=""
        warning
        showCancel
        confirmBtnText="확인"
        cancelBtnText="취소"
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        onConfirm={() => {
          setAlert(null)
          navigate(-1)
        }}
        onCancel={() => setAlert(null)}
      >
        작업을 취소하시겠니까?
      </SweetAlert>
    )

    setAlert(getAlert())
  }

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>운영자 등록</title>
        </MetaTags>

        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="운영자 권한 관리" breadcrumbItem="운영자 등록" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Form onSubmit={handleSubmit(onSubmit, onError)}>
                    <Row>
                      <Col xs={5}>
                        <FormGroup row>
                          <Label sm={4}>*아이디(이메일)</Label>
                          <Col sm={6}>
                            <Input
                              name="email"
                              onChange={(e) => {onChangeEmail(); formOption.email.onChange(e);}}
                              onBlur={formOption.email.onBlur}
                              innerRef={formOption.email.ref}
                              invalid={!!errors.email}

                              valid={state.validEmail && !errors.email}
                            />
                            <FormFeedback valid>
                                사용 가능합니다
                             </FormFeedback>
                            {errors.email && <FormText color="danger"> {errors.email.message} </FormText>}
                          </Col>
                          <Col sm={2}>
                            <Button outline onClick={checkEmail}>
                              조회
                            </Button>
                          </Col>
                        </FormGroup>
                      </Col>
                      <Col xs={1} />
                      <Col xs={5}>
                        <FormGroup row>
                          <Label sm={4}>*비밀번호</Label>
                          <Col sm={8}>
                            <Input
                              name="password"
                              type="password"
                              onChange={formOption.password.onChange}
                              onBlur={formOption.password.onBlur}
                              innerRef={formOption.password.ref}
                              invalid={!!errors.password}
                            />
                            {errors.password && <FormText color="danger"> {errors.password.message} </FormText>}
                          </Col>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={5}>
                        <FormGroup row>
                          <Label sm={4}>*이름</Label>
                          <Col sm={8}>
                            <Input
                              name="name"
                              onChange={formOption.name.onChange}
                              onBlur={formOption.name.onBlur}
                              innerRef={formOption.name.ref}
                              invalid={!!errors.name}
                            />
                            {errors.name && <FormText color="danger"> {errors.name.message} </FormText>}
                          </Col>
                        </FormGroup>
                      </Col>
                      <Col xs={1} />
                      <Col xs={5}>
                        <FormGroup row>
                          <Label sm={4}>*회사명</Label>
                          <Col sm={8}>
                            <Input
                              name="companyName"
                              onChange={formOption.companyName.onChange}
                              onBlur={formOption.companyName.onBlur}
                              innerRef={formOption.companyName.ref}
                              invalid={!!errors.companyName}
                            />  
                            {errors.companyName && <FormText color="danger"> {errors.companyName.message} </FormText>}                          
                          </Col>                          
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={5}>
                        <FormGroup row>
                          <Label sm={4}>*휴대폰전화</Label>
                          <Col sm={8}>
                            <Input
                              name="phone"
                              onChange={formOption.phone.onChange}
                              onBlur={formOption.phone.onBlur}
                              innerRef={formOption.phone.ref}
                              invalid={!!errors.phone}
                            />
                            {errors.phone && <FormText color="danger"> {errors.phone.message} </FormText>}
                          </Col>                          
                        </FormGroup>
                      </Col>
                      <Col xs={1} />
                      <Col xs={5}>
                        <FormGroup row>
                          <Label sm={4}>직급</Label>
                          <Col sm={8}>
                            <Input
                              name="rankName"
                              onChange={formOption.rankName.onChange}
                              onBlur={formOption.rankName.onBlur}
                              innerRef={formOption.rankName.ref}
                              invalid={!!errors.rankName}
                            />
                            {errors.rankName && <FormText color="danger"> {errors.rankName.message} </FormText>}
                          </Col>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={5}>
                        <FormGroup row>
                          <Label sm={4}>부서명</Label>
                          <Col sm={8}>
                            <Input
                              name="departName"
                              onChange={formOption.departName.onChange}
                              onBlur={formOption.departName.onBlur}
                              innerRef={formOption.departName.ref}
                              invalid={!!errors.departName}
                            />
                            {errors.departName && <FormText color="danger"> {errors.departName.message} </FormText>}
                          </Col>
                        </FormGroup>
                      </Col>
                      <Col xs={1} />
                      <Col xs={5}>
                        <FormGroup row>
                          <Label sm={4}>*권한</Label>
                          <Col sm={8}>
                            <Input
                              name="adminRoleId"
                              type="select"
                              onChange={formOption.adminRoleId.onChange}
                              onBlur={formOption.adminRoleId.onBlur}
                              innerRef={formOption.adminRoleId.ref}
                              invalid={!!errors.adminRoleId}
                            >
                              {state.adminRoleList.map((item) => (
                                <option value={item.id}>{item.label}</option>
                              ))}
                            </Input>
                          </Col>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <div className="d-flex gap-2 justify-content-center">
                          <Button
                            color="secondary"
                            onClick={() => {
                              cancel()
                            }}
                          >
                            최소
                          </Button>
                          <Button type="submit" color="primary">
                            저장
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {alert}
    </>
  )
}

export default AdminUserAddForm
