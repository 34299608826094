import { useEffect, useState } from 'react'
import toastr from 'toastr'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory, { PaginationListStandalone, PaginationProvider } from 'react-bootstrap-table2-paginator'
import { APIClient } from '../../lib/apiHelper'

interface UserLogListProps {
  selectedUserId: number | null
  onClose: any
}

const DEFAULT_MAX = 10

const initialSearchState: { [key: string]: any } = {
  page: 1,
  max: DEFAULT_MAX,
}

const UserLogList = ({ selectedUserId }: UserLogListProps) => {
  const [searchState, setSearchState] = useState(initialSearchState)
  const [state, setState] = useState({
    total: 0,
    logList: [] as any[],
  })

  const columns = [
    {
      text: '번호',
      dataField: 'id',
      formatter: (cell: any, row: any) => {
        return <>{row.id}</>
      },
    },
    {
      text: '일시',
      dataField: 'createdAt',
      formatter: (cell: any, row: any) => {
        return <>{row.createdAt}</>
      },
    },
    {
      text: '로그 ID',
      dataField: 'logId',
      formatter: (cell: any, row: any) => {
        return <>..</>
      },
    },
    {
      text: '접속기기',
      dataField: 'device',
      formatter: (cell: any, row: any) => {
        return <>..</>
      },
    },
    {
      text: '브라우저/웹',
      dataField: 'browser',
      width: 120,
      formatter: (cell: any, row: any) => {
        return <>{row.userAgent ? row.userAgent.substring(0, 10) : ''}</>
      },
    },
    {
      text: '유형',
      dataField: 'type',
      formatter: (cell: any, row: any) => {
        return <>..</>
      },
    },
    {
      text: 'os',
      dataField: 'os',
      formatter: (cell: any, row: any) => {
        return <>..</>
      },
    },
    {
      text: '접속 IP주소',
      dataField: 'ip',
      formatter: (cell: any, row: any) => {
        return <>..</>
      },
    },
    {
      text: '방문경로',
      dataField: 'uri',
      formatter: (cell: any, row: any) => {
        return <>..</>
      },
    },
  ]

  useEffect(() => {
    if (selectedUserId) {
      loadLogList(selectedUserId, 1, DEFAULT_MAX)
    }
  }, [selectedUserId])

  const loadLogList = async (userId: number, page: number, max: number) => {
    try {
      const { data: result } = await APIClient.get(`/userCompany/logList?userId=${userId}&page=${page}&max=${max}`)

      console.log('log :', result.data)

      if (result.result) {
        setState((prevState: any) => {
          return {
            ...prevState,
            total: result.data.count,
            logList: result.data.rows,
          }
        })

        setSearchState((prev: any) => {
          return {
            ...searchState,
            page: page
          }
        })
      }
    } catch (error: any) {
      toastr.error(error.message)
    }
  }

  const onTableChange = (
    type: string,
    {
      page,
      sizePerPage,
      filters,
      sortField,
      sortOrder,
      cellEdit,
    }: { page: number; sizePerPage: number; filters: any; sortField: any; sortOrder: any; cellEdit: any }
  ) => {
    if (type === 'pagination') {
      const params = {
        ...searchState,
        page: page,
      }

      if (selectedUserId) {
        loadLogList(selectedUserId, page, searchState.max)
      }
    }
  }

  return (
    <div style={{minHeight: 500}}>
      <div className="mb-1">{state.total}개 전체</div>

      <BootstrapTable
        classes={'table align-middle table-nowrap table-hover'}
        bordered={true}
        striped={true}
        keyField='id'
        columns={columns}
        data={state.logList}
        pagination={paginationFactory({
          sizePerPage: searchState.max,
          page: searchState.page,
          totalSize: state.total, // replace later with size(users),
          //custom: true,
          showTotal: false,
          hideSizePerPage: true
        })}
        responsive
        remote
        onTableChange={onTableChange}
        noDataIndication={<div className="text-center py-4">조회 결과 없음</div>}
      />
    </div>
  )
}

export default UserLogList
