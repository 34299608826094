import React, { MouseEventHandler, useEffect, useRef, useState } from 'react'
import { Link, useLocation, createSearchParams, useNavigate } from 'react-router-dom'
import MetaTags from 'react-meta-tags'
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  FormGroup,
  Label,
  Input,
  FormText,
  FormFeedback,
} from 'reactstrap'
import toastr from "toastr";
import SweetAlert from "react-bootstrap-sweetalert"
import { APIClient } from '../../lib/apiHelper'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import PermissionForm from './PermissionForm'

const Permission = () => {
  const [state, setState] = useState({
    adminRoleList: [] as any[],
    selectedAdminRole: null as any,
    checkedRoleId: null as any
  })
  const [alert, setAlert] = useState<any>(null)

  useEffect(() => {
    loadAdminRoleList()
  }, [])

  const loadAdminRoleList = async () => {
    try {
      const { data: result } = await APIClient.get('/adminRole')

      console.log('loadAdminRoleList:', result)
  
      if (result.result) {
        setState((prev: any) => {
          return {
            ...prev,
            adminRoleList: result.data.adminRoleList,
          }
        })
      }
    } catch (error: any) {
      toastr.error(error.message)
    }
  }

  const onCheck = (e: any, id: number) => {
    if (e.target.checked) {
      //uncheck other roles
      for (const role of state.adminRoleList) {
        if (role.id !== id) {
          const el: any = document.getElementById(`roleId:${role.id}`)
          if (el) {
            el.checked = false
          }
        }
      }
    }

    setState((prev: any) => {
      return {
        ...prev,
        checkedRoleId: e.target.checked ? id : null
      }
    })
  }

  const onEdit = (e: any, id: number) => {
    const adminRole = state.adminRoleList.find((item) => item.id === id)

    setState((prev: any) => {
      return {
        ...prev,
        selectedAdminRole: adminRole,
      }
    })
  }

  const clearRoles = () => {
    console.log('clearing roles ...')

    //uncheck all
    for (const role of state.adminRoleList) {
      const el: any = document.getElementById(`roleId:${role.id}`)
      if (el) {
        el.checked = false
      }
    }

    setState((prev: any) => {
      return {
        ...prev,
        selectedAdminRole: null,
        checkedRoleId: null
      }
    })
  }

  const handleDelete = () => {
    const getAlert = () => (
      <SweetAlert
            title="정말 삭제 하시겠니까?"
            warning
            showCancel
            confirmBtnText="확인"
            cancelBtnText="취소"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              deleteAdminRole()
              setAlert(null)
              console.log('confirm')
            }}
            onCancel={() => setAlert(null)}
          >
            삭제된 권한을 복원할 수 없습니다!
          </SweetAlert>
    )

    setAlert(getAlert())
  }

  const deleteAdminRole = async () => {
    if (state.checkedRoleId) {
      const adminRoleId = state.checkedRoleId

      try {
        const {data: result} = await APIClient.remove(`/adminRole/${adminRoleId}`)

        if (result.result) {
          toastr.success('운영자 권한이 삭제 되었습니다')
  
          //reload
          clearRoles()
          loadAdminRoleList()
        }
      } catch (error: any) {
        toastr.error(error.message)
      }
      
    }
  }

  const onOrderChange = async (adminRoleId: number, direction: string) => {
    const roleList = state.adminRoleList.map(adminRole => {
      return {
        id: adminRole.id,
        ordering: adminRole.ordering
      }
    })

    if (roleList.length === 0) {
      return
    }

    const currIndex = roleList.findIndex(role => role.id === adminRoleId)

    if (currIndex === -1) {
      return
    }

    if (direction === 'up') {
      if (currIndex === 0) {
        return
      }

      const oldOrder = roleList[currIndex].ordering
      roleList[currIndex].ordering = roleList[currIndex - 1].ordering
      roleList[currIndex - 1].ordering = oldOrder
    }

    if (direction === 'down') {
      if (currIndex === roleList.length - 1) {
        return
      }

      const oldOrder = roleList[currIndex].ordering
      roleList[currIndex].ordering = roleList[currIndex + 1].ordering
      roleList[currIndex + 1].ordering = oldOrder
    }

    try {
      const params = {
        roleList
      }

      console.log('onOrderChange:', params)

      const {data: result} = await APIClient.post(`/adminRole/changeOrder`, params)

      if (result.result) {
        toastr.success('순서 변경되었습니다')

        //reload
        clearRoles()
        loadAdminRoleList()
      }
    } catch (error: any) {
      toastr.error(error.message)
    }
  }

  const onSaveCallback = () => {
    //reload
    clearRoles()
    loadAdminRoleList()
  }

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>운영자 권한설정</title>
        </MetaTags>

        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="운영자 권한 관리" breadcrumbItem="운영자 권한 설정" />

          <Row className="mb-3">
            <Col>
              <div className="text-sm-end d-flex gap-3 justify-content-end">
                <Button type="button" color="danger" className="font-16 btn-block btn btn-primary" onClick={handleDelete} disabled={state.checkedRoleId ? false : true}>
                  권한 삭제
                </Button>
                <Button type="button" color="primary" className="font-16 btn-block btn btn-success" onClick={clearRoles}>
                  <i className="mdi mdi-plus-circle-outline me-1" />
                  권한 추가
                </Button>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="4">
              <Card>
                <CardBody>
                  {state.adminRoleList.map((item) => {
                    return (
                      <div className="d-flex justify-content-between align-items-center mb-2" key={item.id}>
                        <FormGroup check inline>
                          <Input type="checkbox" id={`roleId:${item.id}`} value={item.id} onClick={(e) => onCheck(e, item.id)} />
                          <Label for={`roleId:${item.id}`}>{item.name}</Label>
                        </FormGroup>

                        <div className="d-flex gap-2">
                          <Button size="sm" color="light" onClick={() => onOrderChange(item.id, 'up')}>
                            <i className="bx bx-up-arrow-alt fs-5"></i>
                          </Button>
                          <Button size="sm" color="light" onClick={() => onOrderChange(item.id, 'down')}>
                            <i className="bx bx-down-arrow-alt fs-5"></i>
                          </Button>
                          <Button size="sm" color="light" onClick={(e) => onEdit(e, item.id)}>
                            <i className="bx bx-edit-alt fs-5"></i>
                          </Button>
                        </div>
                      </div>
                    )
                  })}
                </CardBody>
              </Card>
            </Col>
            <Col lg="8">
              <Card>
                <CardBody>
                  <PermissionForm onSaveCallback={onSaveCallback} selectedAdminRole={state.selectedAdminRole} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {alert}
    </>
  )
}

export default Permission
