import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Card, CardBody, Col, Container, Row, Button, Form, FormGroup, Label, Input, Table } from 'reactstrap'
import toastr from 'toastr'
import env from "../../lib/env"
import { APIClient } from '../../lib/apiHelper'

interface NoticeDetailProps {
  selectedId: number | null
  onClose: any
}

const NoticeDetail = ({ selectedId, onClose }: NoticeDetailProps) => {
  const nativate = useNavigate()
  const [state, setState] = useState({
    notice: null as any,
  })

  useEffect(() => {
    if (selectedId) {
      loadDetail(selectedId)
    }
  }, [selectedId])

  const loadDetail = async (id: number) => {
    try {
      const { data: result } = await APIClient.get(`/board/notice/${id}`)

      if (result.result) {
        const notice = result.data.notice

        console.log('notice:', notice)

        setState({
          ...state,
          notice,
        })
      }
    } catch (error: any) {
      toastr.error(error.message)
    }
  }

  return (
    <>
      <Container fluid>
        <Card>
          <CardBody>
            <div className="table-responsive mb-3">
              <Table className="table mb-0">
                {/* <thead >
                  <tr>
                    <th className="text-center py-1">알림톡</th>
                  </tr>
                </thead> */}
                <tbody>
                  <tr>
                    <td className="w-25">제목</td>
                    <td>{state.notice?.title}</td>
                  </tr>
                  <tr>
                    <td className="w-25">작성자</td>
                    <td>
                      {state.notice?.adminUser?.name} ({state.notice?.createdAt})
                    </td>
                  </tr>
                  <tr>
                    <td className="w-25"> 게시글</td>
                    <td>
                      <div dangerouslySetInnerHTML={{ __html: state.notice?.content }} style={{ minHeight: 250 }}></div>
                    </td>
                  </tr>
                  <tr>
                    <td className="w-25">첨부파일</td>
                    <td>
                      {!state.notice?.files || state.notice?.files.length === 0 ? (
                        '첨부파일이 없음'
                      ) : (
                        <div>
                          {state.notice?.files.map((file: any, index: number) => {
                            return <div className="mb-2" key={`attach_file_${index}`}><a href={`${env.SITE_URL}${file.url}`} target="_blank">{file.fileName}</a></div>
                          })}
                        </div>
                      )}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>

            <Row>
              <Col>
                <div className="d-flex gap-2 justify-content-center">
                  <Button
                    type="button"
                    color="secondary"
                    onClick={() => {
                      onClose(true)
                      nativate(`/board/notice/edit/${selectedId}`)
                    }}
                  >
                    수정
                  </Button>
                  <Button
                    type="button"
                    color="primary"
                    onClick={() => {
                      onClose(false)
                    }}
                  >
                    확인
                  </Button>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </>
  )
}

export default NoticeDetail
