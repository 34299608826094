import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation, createSearchParams, useNavigate, useParams } from 'react-router-dom'
import MetaTags from 'react-meta-tags'
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  FormFeedback,
  Alert,
} from 'reactstrap'
import toastr from 'toastr'
import { ContentState, convertToRaw, EditorState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { useForm } from 'react-hook-form'
import { APIClient } from '../../lib/apiHelper'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import TermsList from './TermsList'
import moment from 'moment'

enum TermsType {
  SERVICE_TERM = '서비스 이용약관',
  PRIVACY_POLICY = '개인정보 처리방침',
  LOCATION_BASED_SERVICE = '위치기반서비스 이용약관',
}

interface TermFormProps {
  termsType: string
}

const TermForm = ({ termsType }: TermFormProps) => {
  const navigate = useNavigate()
  const locationParams = useParams()

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm()
  const [state, setState] = useState({
    terms: null as any,
    editorState: EditorState.createEmpty(),
  })
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [formError, setFormError] = useState('')

  const title = TermsType[termsType as keyof typeof TermsType]

  const formOption = {
    termsDate: register('termsDate', { required: true }),
  }

  useEffect(() => {
    if (locationParams.termsId) {
      loadDetail(locationParams.termsId)
    } else {
      loadDetail()
    }
  }, [locationParams])

  const loadDetail = async (id?: string) => {
    const url = id ? `/terms/${id}` : `/terms/last?type=${termsType}`

    try {
      const { data: result } = await APIClient.get(url)

      if (result.result) {
        const terms = result.data.terms

        if (terms) {
          console.log('terms:', terms)

          const contentBlock = htmlToDraft(terms.content)
          const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
          const editorState = EditorState.createWithContent(contentState)

          setState((prev: any) => {
            return {
              ...prev,
              terms: terms,
              editorState,
            }
          })

          const values = {
            content: terms.content,
            termsDate: moment(terms.termsDate).format('YYYY-MM-DD'),
          }

          console.log('values:', values)

          reset(values)
        } else {
          setState((prev: any) => {
            return {
              ...prev,
              terms: null,
            }
          })

          const values = {
            content: '',
            termsDate: '',
          }

          console.log('values:', values)

          reset(values)
        }
      }
    } catch (error: any) {
      toastr.error(error.message)
    }
  }

  const onSubmit = async (data: any) => {
    console.log('onSubmit', data)

    const contentState = state.editorState.getCurrentContent()

    if (!contentState.hasText()) {
      setFormError('내용 입력해주세요')

      return
    } else {
      setFormError('')
    }

    const content = draftToHtml(convertToRaw(contentState))

    console.log('content:', content)

    const params = {
      type: termsType,
      content: content,
      termsDate: data.termsDate,
      visible: true,
    }

    if (!state.terms?.id) {
      try {
        const { data: result } = await APIClient.post(`/terms`, params)

        if (result.result) {
          toastr.success(`${title} 등록 되었습니다`)
        }
      } catch (error: any) {
        toastr.error(error.message)
      }
    } else {
      try {
        const { data: result } = await APIClient.put(`/terms/${state.terms.id}`, params)

        if (result.result) {
          toastr.success(`${title} 수정 되었습니다`)
        }
      } catch (error: any) {
        toastr.error(error.message)
      }
    }
  }

  const onError = (d: any) => {
    console.error('error', d)
  }

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
  }

  const handleOpenModal = () => {
    setIsModalOpen(true)
  }

  const handleCloseModal = (reload: boolean) => {
    setIsModalOpen(false)
  }

  const onEditorStateChange = (editorState: any) => {
    setState((prev: any) => {
      return {
        ...prev,
        editorState,
      }
    })
  }

  return (
    <div className="page-content">
      <MetaTags>
        <title>{title}</title>
      </MetaTags>

      <Container fluid>
        {/* Render Breadcrumbs */}
        <Breadcrumbs title="" breadcrumbItem={title} />

        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <Form onSubmit={handleSubmit(onSubmit, onError)}>
                  <Row>
                    <Col xs={12}>
                      <div className="d-flex flex-row justify-content-between align-items-center mb-3">
                        <div className="fs-5 fw-bold">{title} 등록</div>

                        <Button type="button" color="success" onClick={handleOpenModal}>
                          약관 관리
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  {formError && (
                    <Alert color="danger" role="alert">
                      {formError}
                    </Alert>
                  )}
                  <Row>
                    <Col xs={12}>
                      <FormGroup row>
                        <Label sm={2}>*내용</Label>
                        <Col sm={10}>
                          {/* <Input
                            name="content"
                            type="textarea"
                            rows={8}
                            onChange={formOption.content.onChange}
                            onBlur={formOption.content.onBlur}
                            innerRef={formOption.content.ref}
                            invalid={!!errors.content}
                          /> */}
                          <Editor
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            placeholder="내용 입력..."
                            localization={{
                              locale: 'ko',
                            }}
                            editorState={state.editorState}
                            onEditorStateChange={onEditorStateChange}
                          />
                        </Col>
                      </FormGroup>

                      <FormGroup row>
                        <Label sm={2}>*등록일</Label>
                        <Col sm={3}>
                          {/* <div className="d-flex align-items-center gap-2"> */}
                          <Input
                            name="termsDate"
                            type="date"
                            onChange={formOption.termsDate.onChange}
                            onBlur={formOption.termsDate.onBlur}
                            innerRef={formOption.termsDate.ref}
                            invalid={!!errors.termsDate}
                          />
                          {/* <div>년</div>
                          </div> */}
                        </Col>
                        {/* <Col sm={2}>
                          <div className="d-flex align-items-center gap-2">
                            <Input
                              name="termsDateMonth"
                              onChange={formOption.termsDateMonth.onChange}
                              onBlur={formOption.termsDateMonth.onBlur}
                              innerRef={formOption.termsDateMonth.ref}
                              invalid={!!errors.termsDateMonth}
                            />
                            <div>월</div>
                          </div>
                        </Col>

                        <Col sm={2}>
                          <div className="d-flex align-items-center gap-2">
                            <Input
                              name="termsDateDay"
                              onChange={formOption.termsDateDay.onChange}
                              onBlur={formOption.termsDateDay.onBlur}
                              innerRef={formOption.termsDateDay.ref}
                              invalid={!!errors.termsDateDay}
                            />
                            <div>일</div>
                          </div>
                        </Col> */}
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <div className="d-flex gap-2 justify-content-center">
                        <Button type="submit" color="primary">
                          저장
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      <Modal isOpen={isModalOpen} toggle={toggleModal} size="lg">
        <ModalHeader toggle={toggleModal} tag="h4">
          {'약관 관리'}
        </ModalHeader>
        <ModalBody>
          <TermsList termsType={termsType} onClose={handleCloseModal} />
        </ModalBody>
      </Modal>
    </div>
  )
}

export default TermForm
