import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation, createSearchParams, useNavigate } from 'react-router-dom'
import MetaTags from 'react-meta-tags'
import { Card, CardBody, Col, Container, Row, Button, Form, FormGroup, FormFeedback, Label, Input, FormText } from 'reactstrap'
import toastr from 'toastr'
import AsyncSelect from 'react-select/async'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import { Controller, useForm } from 'react-hook-form'
import { APIClient } from '../../lib/apiHelper'
import SweetAlert from 'react-bootstrap-sweetalert'

const UserCompanyAddForm = () => {
  const navigate = useNavigate()
  const {
    register,
    control,
    getValues,
    setValue,
    trigger,
    handleSubmit,
    reset,
    formState: { errors, dirtyFields },
  } = useForm()

  const [state, setState] = useState({
    companyList: [] as any[],
    roleList: [] as any[],
    validEmail: undefined,
  })
  const [alert, setAlert] = useState<any>(null)

  useEffect(() => {
    setInitialValue()
  }, [])

  const setInitialValue = () => {
    reset({
      state: 'SUCCESS',
    })
  }

  const loadRoleList = async (companyId: number) => {
    try {
      const { data: result } = await APIClient.get(`/company/role?companyId=${companyId}`)

      if (result.result) {
        const roleList = result.data.roleList

        setState((prev: any) => {
          return {
            ...prev,
            roleList,
          }
        })

        if (roleList && roleList.length > 0) {
          const defaultRoleId = roleList[0].id + ''

          setTimeout(() => {
            console.log('resetting defaultRoleId:', defaultRoleId)
  
            setValue('roleId', defaultRoleId)
          }, 200)
        }
      }
    } catch (error: any) {
      toastr.error(error.message)
    }
  }

  const onSubmit = async (data: any) => {
    console.log('onSubmit', data)

    const params = {
      user: {
        email: data.email,
        password: data.password,
        name: data.name,
        phone: data.phone,
        mailing: true,
        currentCompanyId: data.company.id,
      },
      companyId: data.company.id,
      roleId: data.roleId,
      joinDate: data.joinDate,
      companyNumber: data.companyNumber,
      state: data.state,
    }

    console.log('params:', params)

    try {
      const { data: result } = await APIClient.post(`/userCompany`, params)

      if (result.result) {
        toastr.success('사용자 등록 되었습니다')

        navigate('/user')
      }
    } catch (error: any) {
      toastr.error(error.message)
    }
  }

  const onError = (d: any) => {
    console.error('error', d)
  }

  const validateEmail = () => {
    console.log('state.validEmail:', state.validEmail)

    if (state.validEmail === undefined) {
      return '이메일 조회해 주세요'
    } else if (state.validEmail === false) {
      return '사용 불가능한 이메일입니다'
    } else {
      return true
    }
  }

  const formOption = {
    email: register('email', {
      required: true,
      maxLength: { value: 33, message: '최대 33자' },
      pattern: { value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, message: '유효한 이메일을 입력해주세요' },
      validate: validateEmail,
    }),
    name: register('name', { required: true, maxLength: { value: 15, message: '한글, 영문(최대15자)' } }),
    phone: register('phone', { required: true, maxLength: { value: 11, message: '최대 11자' } }),
    companyNumber: register('companyNumber', { required: false, maxLength: { value: 15, message: '최대 15자' } }),
    joinDate: register('joinDate', { required: false }),
    password: register('password', {
      required: true,
      pattern: {value: /^(?=.*\d)(?=.*[a-zA-Z]).{8,16}$/, message: '숫자, 영문 혼합 (최소8~최대 16자)'}
    }),
    company: register('company', { required: true, maxLength: { value: 15, message: '최대 15자' } }),
    roleId: register('roleId', { required: true }),
    state: register('state', { required: true }),
  }

  const checkEmail = async () => {
    console.log('checkEmail')

    try {
      const values = getValues()
      const email = values.email

      if (email) {
        const { data: result } = await APIClient.get(`/userCompany/searchId?email=${email}`)

        console.log('result:', result)

        if (result.result) {
          //make valid
          setState((prev: any) => {
            return {
              ...prev,
              validEmail: result.data.count === 0,
            }
          })

          trigger('email')
        }
      }
    } catch (error: any) {
      toastr.error(error.message)
    }
  }

  const onChangeEmail = () => {
    setState((prev: any) => {
      return {
        ...prev,
        validEmail: undefined
      }
    })
  }

  const loadOptions = (inputValue: string, callback: (options: any[]) => void) => {
    APIClient.get(`/company/simpleSearch?q=${inputValue}`)
      .then((res) => {
        console.log('loadOptions:', res)

        const companyList = res.data.data.rows
        const optionList = companyList.map((company: any) => {
          return {
            id: company.id,
            value: company.id,
            label: company.name,
          }
        })

        callback(optionList)
      })
      .catch((error) => {
        callback([])
      })
  }

  const handleCompanyChange = (val: any) => {
    console.log('handleCompanyChange:', val)

    loadRoleList(val.id)
  }

  const cancel = () => {
    const getAlert = () => (
      <SweetAlert
        title=""
        warning
        showCancel
        confirmBtnText="확인"
        cancelBtnText="취소"
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        onConfirm={() => {
          setAlert(null)
          navigate(-1)
        }}
        onCancel={() => setAlert(null)}
      >
        작업을 취소하시겠니까?
      </SweetAlert>
    )

    setAlert(getAlert())
  }

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>사용자 등록</title>
        </MetaTags>

        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="회원관리" breadcrumbItem="사용자 등록" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Form onSubmit={handleSubmit(onSubmit, onError)}>
                    <Row>
                      <Col xs={5}>
                        <FormGroup row>
                          <Label sm={4}>*아이디(이메일)</Label>
                          <Col sm={6}>
                            <Input
                              name="email"
                              onChange={(e) => {onChangeEmail(); formOption.email.onChange(e);}}
                              onBlur={formOption.email.onBlur}
                              innerRef={formOption.email.ref}
                              invalid={!!errors.email}

                              valid={state.validEmail && !errors.email}
                            />
                             <FormFeedback valid>
                                사용 가능합니다
                             </FormFeedback>
                            {errors.email && <FormText color="danger"> {errors.email.message} </FormText>}
                          </Col>
                          <Col sm={2}>
                            <Button outline onClick={checkEmail}>
                              조회
                            </Button>
                          </Col>
                        </FormGroup>
                      </Col>
                      <Col xs={1} />
                      <Col xs={5}>
                        <FormGroup row>
                          <Label sm={4}>*비밀번호</Label>
                          <Col sm={8}>
                            <Input
                              name="password"
                              type="password"
                              onChange={formOption.password.onChange}
                              onBlur={formOption.password.onBlur}
                              innerRef={formOption.password.ref}
                              invalid={!!errors.password}
                            />
                            {errors.password && <FormText color="danger"> {errors.password.message} </FormText>}
                          </Col>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={5}>
                        <FormGroup row>
                          <Label sm={4}>*이름</Label>
                          <Col sm={8}>
                            <Input
                              name="name"
                              onChange={formOption.name.onChange}
                              onBlur={formOption.name.onBlur}
                              innerRef={formOption.name.ref}
                              invalid={!!errors.name}
                            />
                            {errors.name && <FormText color="danger"> {errors.name.message} </FormText>}
                          </Col>
                        </FormGroup>
                      </Col>
                      <Col xs={1} />
                      <Col xs={5}>
                        <FormGroup row>
                          <Label sm={4}>*회사명</Label>
                          <Col sm={8}>
                            <Controller
                              name="company"
                              control={control}
                              render={({ field }) => (
                                <AsyncSelect
                                  {...field}
                                  cacheOptions
                                  styles={{
                                    control: (styles) => ({
                                      ...styles,
                                      borderColor: errors.company ? 'red' : styles.borderColor,
                                      '&:hover': {
                                        borderColor: errors.company ? 'red' : styles.borderColor,
                                      },
                                    }),
                                  }}
                                  loadOptions={loadOptions}
                                  defaultOptions
                                  onChange={(e) => {handleCompanyChange(e); field.onChange(e)}}
                                />
                              )}
                            />
                          </Col>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={5}>
                        <FormGroup row>
                          <Label sm={4}>*휴대폰전화</Label>
                          <Col sm={8}>
                            <Input
                              name="phone"
                              onChange={formOption.phone.onChange}
                              onBlur={formOption.phone.onBlur}
                              innerRef={formOption.phone.ref}
                              invalid={!!errors.phone}
                            />
                            {errors.phone && <FormText color="danger"> {errors.phone.message} </FormText>}
                          </Col>
                        </FormGroup>
                      </Col>
                      <Col xs={1} />
                      <Col xs={5}>
                        <FormGroup row tag="fieldset">
                          <legend className="col-form-label col-sm-4">*권한</legend>
                          <Col sm={8} className="pt-2">
                            {state.roleList.map((role) => (
                              <FormGroup check inline key={`role_key_${role.id}`}>
                                <Input
                                  type="radio"
                                  name="roleId"
                                  value={role.id}
                                  onChange={formOption.roleId.onChange}
                                  onBlur={formOption.roleId.onBlur}
                                  innerRef={formOption.roleId.ref}
                                  invalid={!!errors.roleId}
                                />
                                <Label>{role.name}</Label>
                              </FormGroup>
                            ))}
                          </Col>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={5}>
                        <FormGroup row>
                          <Label sm={4}>사번</Label>
                          <Col sm={8}>
                            <Input
                              name="companyNumber"
                              onChange={formOption.companyNumber.onChange}
                              onBlur={formOption.companyNumber.onBlur}
                              innerRef={formOption.companyNumber.ref}
                              invalid={!!errors.companyNumber}
                            />
                            {errors.companyNumber && <FormText color="danger"> {errors.companyNumber.message} </FormText>}
                          </Col>
                        </FormGroup>
                      </Col>
                      <Col xs={1} />
                      <Col xs={5}>
                        <FormGroup row tag="fieldset">
                          <legend className="col-form-label col-sm-4">*재직현황</legend>
                          <Col sm={8} className="pt-2">
                            <FormGroup check inline>
                              <Input
                                type="radio"
                                name="state"
                                value="SUCCESS"
                                onChange={formOption.state.onChange}
                                onBlur={formOption.state.onBlur}
                                innerRef={formOption.state.ref}
                                invalid={!!errors.state}
                              />
                              <Label>재직</Label>
                            </FormGroup>
                            <FormGroup check inline>
                              <Input
                                type="radio"
                                name="state"
                                value="LEAVE"
                                onChange={formOption.state.onChange}
                                onBlur={formOption.state.onBlur}
                                innerRef={formOption.state.ref}
                                invalid={!!errors.state}
                              />
                              <Label>퇴사</Label>
                            </FormGroup>
                            <FormGroup check inline>
                              <Input
                                type="radio"
                                name="state"
                                value="TEMPORAL_LEAVE"
                                onChange={formOption.state.onChange}
                                onBlur={formOption.state.onBlur}
                                innerRef={formOption.state.ref}
                                invalid={!!errors.state}
                              />
                              <Label>휴직</Label>
                            </FormGroup>
                          </Col>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={5}>
                        <FormGroup row>
                          <Label sm={4}>입사일</Label>
                          <Col sm={8}>
                            <Input
                              name="joinDate"
                              type="date"
                              onChange={formOption.joinDate.onChange}
                              onBlur={formOption.joinDate.onBlur}
                              innerRef={formOption.joinDate.ref}
                              invalid={!!errors.joinDate}
                            />
                          </Col>
                        </FormGroup>
                      </Col>
                      <Col xs={2}></Col>
                      <Col xs={5}></Col>
                    </Row>

                    <Row>
                      <Col>
                        <div className="d-flex gap-2 justify-content-center">
                          <Button
                            color="secondary"
                            onClick={() => {
                              cancel()
                            }}
                          >
                            최소
                          </Button>
                          <Button type="submit" color="primary">
                            저장
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {alert}
    </>
  )
}

export default UserCompanyAddForm
