import React, { useEffect, useRef } from 'react'

//Simple bar
import SimpleBar from 'simplebar-react'

// MetisMenu
import MetisMenu from 'metismenujs'
import { Link, useLocation } from 'react-router-dom'

//i18n
import { useTranslation } from 'react-i18next'

type SidebarContentProps = {
  // type: string;
}

const SidebarContent = (props: SidebarContentProps) => {
  const location = useLocation()
  const { t } = useTranslation()
  const ref = useRef<any>()

  useEffect(() => {
    //console.log('init menu ================')
    const mm = new MetisMenu('#side-menu')

    return () => {
      mm.dispose()
    }
  }, [])

  useEffect(() => {
    const pathName = location.pathname
    updateMenu(pathName)
    
  }, [location.pathname])

  // useEffect(() => {
  //   if (ref.current) {
  //     console.log('calc ref')
  //     ref.current.recalculate()
  //   }
  // })

  const clearMenu = () => {
    const ul = document.getElementById('side-menu')
    if (ul) {
      //remove active 
      const aList = ul.querySelectorAll(":scope a.active")

      aList.forEach(a => {
        a.classList.remove('active')
      })

      //remove mm-active
      const liList = ul.querySelectorAll(":scope li.mm-active")

      liList.forEach(li => {
        li.classList.remove('mm-active')
      })

      //remove mm-show
      const ulList = ul.querySelectorAll(":scope ul.mm-show")

      ulList.forEach(ul => {
        ul.classList.remove('mm-show')
      })

      //remove mm-active 
      const expandableAList = ul.querySelectorAll(":scope a.mm-active")

      expandableAList.forEach(expandableA => {
        expandableA.classList.remove('mm-active')
      })
    }
  }

  const updateMenu = (pathName: string) => {
    //console.log('updateMenu ================ ', pathName)

    clearMenu()

    let matchingMenuItem = null
    const ul = document.getElementById('side-menu')
    if (ul) {
      const items = ul!.getElementsByTagName('a')
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }

      if (matchingMenuItem) {
        activateMenu(matchingMenuItem)
      } else {
        //expand menu only
  
        const temp = pathName.split('/')
  
        if (temp.length >= 3) {
          const moduleName = temp[1]

          //console.log('moduleName:', moduleName)
  
          const aTag = ul.querySelector(`a[href^="/${moduleName}"]`)
          if (aTag) {
            const ulTag = aTag.parentElement?.parentElement

            if (ulTag && ulTag.classList.contains('sub-menu')) { //second level
              ulTag.classList.add('mm-show')

              const expandableA = ulTag.previousSibling as HTMLElement

              if (expandableA) {
                expandableA.classList.add('mm-active')
              }
            } else { //first level
              aTag.classList.add('active')
              const liTag = aTag.parentElement
              liTag?.classList.add('mm-active')

            }
          }
        }
      }
  
    }
    
  }

  const activateMenu = (item: any) => {
    ///console.log('item:', item)
    item.classList.add('active')

    const parentLi = item.parentElement //li

    //console.log('parentLi:', parentLi)

    if (parentLi) {
      parentLi.classList.add('mm-active')
      const parentUl = parentLi.parentElement

      if (parentUl) {
        //console.log('parentUl:', parentUl)

        parentUl.classList.add('mm-show') // ul tag

        const parentParentLi = parentUl.parentElement

        //console.log('parentParentLi:', parentParentLi)

        if (parentParentLi) {
          const expandableA = parentParentLi.querySelector(':scope > a.has-arrow')

          //console.log('expandableA:', expandableA)

          if (expandableA) {
            expandableA.classList.add('mm-active')
          }
        }
      }
    }
  }


  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {/* <li className="menu-title">{t("Menu")} </li> */}
            <li>
              <Link to="/dashboard">
                <i className="bx bx-home-circle" />
                <span>{t('대시보드')}</span>
              </Link>
            </li>
            <li>
              <Link to="/user">
                <i className="bx bxs-user-detail" />
                <span>{t('회원 관리')}</span>
              </Link>
            </li>
            <li>
              <Link to="/company">
                <i className="bx bx-buildings"></i>
                <span>{t('회사 관리')}</span>
              </Link>
            </li>
            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-lock-open-alt"></i>
                <span>{t('운영자 권한 관리')}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/adminUser">{t('운영자 리스트')}</Link>
                </li>
                <li>
                  <Link to="/adminUser/permission">{t('운영자 권한 설정')}</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/#" className="has-arrow" >
                <i className="bx bx-list-ul"></i>
                <span>{t('게시판 관리')}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/board/notice">{t('공지사항')}</Link>
                </li>
                <li>
                  <Link to="/board/question">{t('이용문의')}</Link>
                </li>
                <li>
                  <Link to="/board/serviceQuestion">{t('서비스 도입문의')}</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-file"></i>
                <span>{t('약관 관리')}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/terms/serviceTerm">{t('서비스 이용약관')}</Link>
                </li>
                <li>
                  <Link to="/terms/privacyPolicy">{t('개인정보 처리방침')}</Link>
                </li>
                <li>
                  <Link to="/terms/locationBasedServiceTerm">{t('위치기반서비스 이용약관')}</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-message-square-dots"></i>
                <span>{t('카카오 알림톡')}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/allimtalk/setting">{t('카카오 알림톡 설정')}</Link>
                </li>
                <li>
                  <Link to="/allimtalk/template">{t('카카오 알림톡 템플릿 설정')}</Link>
                </li>
                <li>
                  <Link to="/allimtalk/sentList">{t('카카오 알림톡 발송 내역')}</Link>
                </li>
              </ul>
            </li>
            {/* <li>
              <Link to="#">
                <i className="bx bx-receipt"></i>
                <span>{t('결제 관리')}</span>
              </Link>
            </li> */}
            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-line-chart"></i>
                <span>{t('통계')}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/analytics/traffic">{t('방문자 분석')}</Link>
                </li>
                <li>
                  <Link to="/analytics/user">{t('회원 분석')}</Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

export default SidebarContent
