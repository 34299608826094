import React, { ChangeEvent, ChangeEventHandler, useEffect, useRef, useState } from 'react'
import { Link, useLocation, createSearchParams, useNavigate } from 'react-router-dom'
import MetaTags from 'react-meta-tags'
import queryString from 'query-string'
import moment from 'moment'
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  Button,
  Input,
  Form,
  FormGroup,
  Label,
  Table,
} from 'reactstrap'
import toastr from "toastr";
import { APIClient } from '../../lib/apiHelper'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import { useForm } from 'react-hook-form'
import { templateTypeList } from '../../constants/allim'

const Setting = () => {
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm()
  const [state, setState] = useState({
    setting: null as any,
  })

  useEffect(() => {
    loadSetting()
  }, [])

  const genSetting = () => {
    const messageTypeList = templateTypeList.map((templateType) => {
      return {
        name: templateType.name,
        type: templateType.code,
        sendTime: null,

        userSendType: 'MANUAL',
        userTemplateContent: '',
        userTemplateId: '',

        adminSendType: 'MANUAL',
        adminTemplateContent: '',
        adminTemplateId: '',
      }
    })

    return {
      kakaoPlusId: '',
      key: '',
      status: 'INACTIVE',
      messageTypeList,
    }
  }

  const loadSetting = async () => {
    try {
      const { data: result } = await APIClient.get('/allimtalk/setting')

      console.log('loadSetting result:', result)
  
      if (result.result) {
        let setting = result.data.setting
  
        if (!setting) {
          setting = genSetting()
        }
  
        setState((prev: any) => {
          return {
            ...prev,
            setting: setting,
          }
        })
  
        reset(setting)
      }
    } catch (error: any) {
      toastr.error(error.message)
    }
  }

  const onSubmit = async (data: any) => {
    console.log('onSubmit', data)
    console.log('messageTypeList', state.setting.messageTypeList)

    const params = {
      ...data,
      messageTypeList: state.setting.messageTypeList,
    }

    try {
      const { data: result } = await APIClient.put(`/allimtalk/setting`, params)

      if (result.result) {
        toastr.success('카카오 알림톡 설정이 저장 되었습니다')
  
        //reload
        loadSetting()
      }
    } catch (error: any) {
      toastr.error(error.message)
    }
  }

  const onError = (d: any) => {
    console.error('error', d)
  }

  const formOption = {
    kakaoPlusId: register('kakaoPlusId', { required: true }),
    key: register('key', { required: true }),
    status: register('status', { required: true }),
  }

  const handleMessageTypeChange = (e: any, selectedIndex: number, name: string) => {
    let val = e.target.value

    if (name === 'userSendType' || name === 'adminSendType') {
      val = e.target.checked ? 'AUTO' : 'MANUAL'
    }

    const messageTypeList = state.setting.messageTypeList.map((messageType: any, index: number) => {
      if (index === selectedIndex) {
        return {
          ...messageType,
          [name]: val,
        }
      }

      return messageType
    })

    setState((prev: any) => {
      return {
        ...prev,
        setting: {
          ...prev.setting,
          messageTypeList,
        },
      }
    })
  }

  return (
    <div className="page-content">
      <MetaTags>
        <title>카카오 알림톡 설정</title>
      </MetaTags>

      <Container fluid>
        {/* Render Breadcrumbs */}
        <Breadcrumbs title="" breadcrumbItem="카카오 알림톡 설정" />

        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                {state.setting ? (
                  <Form onSubmit={handleSubmit(onSubmit, onError)}>
                    <Row>
                      <Col>
                        <div className="fs-6 fw-bold mb-2">카카오톡 플러스친구 아이디</div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <FormGroup row>
                          <Label sm={4}>플러스친구 아이디</Label>
                          <Col sm={6}>
                            <Input
                              name="kakaoPlusId"
                              onChange={formOption.kakaoPlusId.onChange}
                              onBlur={formOption.kakaoPlusId.onBlur}
                              innerRef={formOption.kakaoPlusId.ref}
                              invalid={!!errors.kakaoPlusId}
                            />
                          </Col>
                          <Col sm={2}>
                            <Button outline type="button">
                              조회
                            </Button>
                          </Col>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup row>
                          <Label sm={4}>발신프로필키</Label>
                          <Col sm={8}>
                            <Input
                              name="key"
                              onChange={formOption.key.onChange}
                              onBlur={formOption.key.onBlur}
                              innerRef={formOption.key.ref}
                              invalid={!!errors.key}
                            />
                          </Col>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <FormGroup row>
                          <Label sm={4}>잔영 포인트</Label>
                          <Col sm={8}>-</Col>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="fs-6 fw-bold mb-2">알림톡 사용 설정</div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <FormGroup row tag="fieldset">
                          <legend className="col-form-label col-sm-4">사용 설정</legend>
                          <Col sm={8} className="pt-2">
                            <FormGroup check inline>
                              <Input
                                type="radio"
                                name="status"
                                value="ACTIVE"
                                onChange={formOption.status.onChange}
                                onBlur={formOption.status.onBlur}
                                innerRef={formOption.status.ref}
                                invalid={!!errors.status}
                              />
                              <Label>사용</Label>
                            </FormGroup>
                            <FormGroup check inline>
                              <Input
                                type="radio"
                                name="status"
                                value="INACTIVE"
                                onChange={formOption.status.onChange}
                                onBlur={formOption.status.onBlur}
                                innerRef={formOption.status.ref}
                                invalid={!!errors.status}
                              />
                              <Label>미사용</Label>
                            </FormGroup>
                          </Col>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="fs-6 fw-bold mb-2">알림톡 발송 조건/문구 설정</div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="table-responsive">
                          <Table className="table mb-0" size="sm">
                            <thead className="table-dark">
                              <tr>
                                <th rowSpan={2} className="text-center">
                                  발송 항목
                                </th>
                                <th rowSpan={2} className="text-center">
                                  발송시간 설정
                                </th>
                                <th colSpan={2} className="text-center">
                                  발송대상 및 알림톡 문구 설정
                                </th>
                              </tr>
                              <tr>
                                <th className="text-center">회원</th>
                                <th className="text-center">운영자</th>
                              </tr>
                            </thead>
                            <tbody>
                              {state.setting.messageTypeList.map((messageType: any, index: number) => {
                                return (
                                  <tr key={`messageType_${index}`}>
                                    <th scope="row" className="text-center">
                                      {messageType.name}
                                    </th>
                                    <td className="text-center"></td>
                                    <td>
                                      <FormGroup check inline>
                                        <Input
                                          type="checkbox"
                                          name="userSendType"
                                          checked={messageType.userSendType === 'AUTO'}
                                          value={messageType.userSendType}
                                          onChange={(e) => handleMessageTypeChange(e, index, 'userSendType')}
                                        />
                                        <Label>자동</Label>
                                      </FormGroup>

                                      <FormGroup>
                                        <Input
                                          type="textarea"
                                          rows="4"
                                          name="userTemplateContent"
                                          value={messageType.userTemplateContent}
                                          onChange={(e) => handleMessageTypeChange(e, index, 'userTemplateContent')}
                                        />
                                      </FormGroup>

                                      <FormGroup>
                                        <Input
                                          type="select"
                                          bsSize="sm"
                                          name="userTemplateId"
                                          value={messageType.userTemplateId}
                                          onChange={(e) => handleMessageTypeChange(e, index, 'userTemplateId')}
                                        >
                                          {templateTypeList.map((item) => (
                                            <option key={`templateType_${index}_${item.code}`} value={item.code}>
                                              {item.name}
                                            </option>
                                          ))}
                                        </Input>
                                      </FormGroup>
                                    </td>
                                    <td>
                                      <FormGroup check inline>
                                        <Input
                                          type="checkbox"
                                          name="adminSendType"
                                          checked={messageType.adminSendType === 'AUTO'}
                                          value={messageType.adminSendType}
                                          onChange={(e) => handleMessageTypeChange(e, index, 'adminSendType')}
                                        />
                                        <Label>자동</Label>
                                      </FormGroup>

                                      <FormGroup>
                                        <Input
                                          type="textarea"
                                          rows="4"
                                          name="adminTemplateContent"
                                          value={messageType.adminTemplateContent}
                                          onChange={(e) => handleMessageTypeChange(e, index, 'adminTemplateContent')}
                                        />
                                      </FormGroup>

                                      <FormGroup>
                                        <Input
                                          type="select"
                                          bsSize="sm"
                                          name="adminTemplateId"
                                          value={messageType.adminTemplateId}
                                          onChange={(e) => handleMessageTypeChange(e, index, 'adminTemplateId')}
                                        >
                                          {templateTypeList.map((item) => (
                                            <option key={`templateType_${index}_${item.code}`} value={item.code}>
                                              {item.name}
                                            </option>
                                          ))}
                                        </Input>
                                      </FormGroup>
                                    </td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </Table>
                        </div>
                      </Col>
                    </Row>

                    <Row className="mt-3">
                      <Col>
                        <div className="d-flex gap-2 justify-content-center">
                          <Button
                            color="secondary"
                            onClick={() => {
                              navigate(-1)
                            }}
                          >
                            최소
                          </Button>
                          <Button type="submit" color="primary">
                            저장
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                ) : null}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Setting
