import { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import toastr from "toastr";
import { APIClient } from '../../lib/apiHelper'

let initialState: { [key: string]: any } = {
  options: {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },

    colors: ["#34c38f"],
    grid: {
      borderColor: "#f1f1f1",
    },
    xaxis: {
      categories: [],
    },
  },
  series: [
    {
      data: [],
    },
  ],
}

const TopCompany = () => {
  const [state, setState] = useState(initialState)

  useEffect(() => {
    loadData()
  }, [])

  const loadData = async () => {
    try {
      const { data: result } = await APIClient.get('/stat/getTopCompanyList')

      console.log('getTopCompanyList:', result)
  
      const categories = result.data.data.map((item: any) => item.name)
      const seriesData = result.data.data.map((item: any) => Number(item.user_count))
      const series = [
        {
          data: seriesData,
        },
      ]
  
      console.log('categories:', categories, ' series:', series)
  
      if (result.result) {
        setState((prev: any) => {
          return {
            ...prev,
            options: {
              ...prev.options,
              xaxis: {
                ...prev.options.xaxis,
                categories: categories,
              },
            },
            series: series,
          }
        })
      }
    } catch (error: any) {
      toastr.error(error.message)
    }
  }

  return (
    <>
      {state.series.length > 0 ? (
        <ReactApexChart options={state.options} series={state.series} type="bar" height="350" />
      ) : (
        <div style={{ width: '100%', height: 350 }} className="d-flex align-items-center justify-content-center">no data</div>
      )}
    </>
  )
}

export default TopCompany
