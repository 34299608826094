import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation, createSearchParams, useNavigate } from 'react-router-dom'
import { Card, CardBody, Col, Media, Row, Button, Form, FormGroup, Label, Input, FormText, FormFeedback } from 'reactstrap'
import toastr from 'toastr'
import { Controller, useForm } from 'react-hook-form'
import Select from 'react-select'
import { APIClient } from '../../lib/apiHelper'
import moment from 'moment'

interface UserCompanyEditFormProps {
  selectedId: number | null
  onClose: (reload: boolean) => void
}

const UserCompanyEditForm = ({ selectedId, onClose }: UserCompanyEditFormProps) => {
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm()

  const [state, setState] = useState({
    userCompany: null as any,
    roleList: [] as any[],
    departList: [] as any[],
    positionList: [] as any[],
    rankList: [] as any[],
    groupList: [] as any[],
    areaList: [] as any[]
  })

  const [changePassword, setChangePassword] = useState(false)

  const formOption = {
    phone: register('phone', { required: true }),
    companyNumber: register('companyNumber', { required: false }),
    joinDate: register('joinDate', { required: false }),
    password: register('password', {
      required: true,
      pattern: {value: /^(?=.*\d)(?=.*[a-zA-Z]).{8,16}$/, message: '숫자, 영문 혼합 (최소8~최대 16자)'}
    }),

    departId: register('departId', { required: false }),
    positionId: register('positionId', { required: false }),
    rankId: register('rankId', { required: false }),
    groupId: register('groupId', { required: false }),
    areaId: register('areaId', { required: false }),
    // roleId: register('roleId', { required: true }),
    // state: register('state', { required: true }),
  }

  useEffect(() => {
    if (selectedId) {
      loadDetail(selectedId)
    }
  }, [selectedId])

  const loadRoleList = async (companyId: number) => {
    try {
      const { data: result } = await APIClient.get(`/company/role?companyId=${companyId}`)

      if (result.result) {
        const roleList = result.data.roleList

        console.log('roleList:', roleList)

        setState((prev: any) => {
          return {
            ...prev,
            roleList,
          }
        })
      }
    } catch (error: any) {
      toastr.error(error.message)
    }
  }

  const loadDetail = async (userCompanyId: number) => {
    try {
      const { data: result } = await APIClient.get(`/userCompany/${userCompanyId}`)

      if (result.result) {
        const userCompany = result.data.userCompany

        const departList = result.data.departList.map((item: any) => {
          return {
            id: item.id,
            value: item.id,
            label: item.name,
          }
        })
        const positionList = result.data.positionList.map((item: any) => {
          return {
            id: item.id,
            value: item.id,
            label: item.name,
          }
        })
        const roleList = result.data.roleList.map((item: any) => {
          return {
            id: item.id,
            value: item.id,
            label: item.name,
          }
        })
        const rankList = result.data.rankList.map((item: any) => {
          return {
            id: item.id,
            value: item.id,
            label: item.name,
          }
        })
        const groupList = result.data.groupList.map((item: any) => {
          return {
            id: item.id,
            value: item.id,
            label: item.name,
          }
        })

        console.log('departList:', departList)

        setState((prev: any) => {
          return {
            ...prev,
            userCompany: userCompany,
            departList: departList,
            positionList: positionList,
            roleList: roleList,
            rankList: rankList,
            groupList: groupList,
          }
        })

        //set form data
        const values = {
          password: '________a1',
          departId: userCompany.departId,
          positionId: userCompany.positionId,
          rankId: userCompany.rankId,
          phone: userCompany.user.phone,

          groupId: result.data.groupId,
          areaId: result.data.areaId,

          companyNumber: userCompany.companyNumber,
          joinDate: userCompany.joinDate ? moment(userCompany.joinDate).format('YYYY-MM-DD') : '',
        }

        reset(values)
      }
    } catch (error: any) {
      toastr.error(error.message)
    }
  }

  const onSubmit = async (data: any) => {
    console.log('onSubmit', data)

    const params = {
      user: {
        password: data.password,
        phone: data.phone,
      },
      departId: data.departId,
      positionId: data.positionId,
      rankId: data.rankId,
      groupId: data.groupId,
      companyNumber: data.companyNumber,
      joinDate: data.joinDate,
    }

    console.log('params:', params)

    try {
      const { data: result } = await APIClient.put(`/userCompany/${state.userCompany.id}`, params)

      if (result.result) {
        toastr.success('사용자 정보 수정 되었습니다')

        onClose(true)
      }
    } catch (error: any) {
      toastr.error(error.message)
    }
  }

  const onError = (d: any) => {
    console.error('error', d)
  }

  const resetPassword = () => {
    setChangePassword(true)

    setValue('password', '')
  }

  return (
    <>
      {state.userCompany && (
        <>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <Media className="mb-5">
                        <div className="me-3">
                          {/* <img src={avatar} alt="" className="avatar-md rounded-circle img-thumbnail" /> */}
                          {!state.userCompany?.user?.profile ? (
                            <div className="avatar-md">
                              <span className="avatar-title rounded-circle">{state.userCompany.user.name.charAt(0)}</span>
                            </div>
                          ) : (
                            <div>
                              <img className="rounded-circle avatar-md" src={state.userCompany.user.profile} alt={state.userCompany.user.name} />
                            </div>
                          )}
                        </div>
                        <Media body className="align-self-center">
                          <div className="text-muted">
                            <h5>{state.userCompany.user.name}</h5>
                            <p className="mb-1">{state.userCompany.company.name}</p>
                          </div>
                        </Media>
                      </Media>
                    </Col>
                  </Row>
                  <Form onSubmit={handleSubmit(onSubmit, onError)}>
                    <FormGroup>
                      <Label>*아이디(이메일)</Label>
                      <Row>
                        <Col>
                          <Input name="email" type="email" readOnly={true} value={state.userCompany.user.email} />
                        </Col>
                      </Row>
                    </FormGroup>

                    <FormGroup row>
                      <Label>*비밀번호</Label>
                      <Col sm={9}>
                        <Input
                          name="password"
                          type="password"
                          readOnly={!changePassword}
                          onChange={formOption.password.onChange}
                          onBlur={formOption.password.onBlur}
                          innerRef={formOption.password.ref}
                          invalid={!!errors.password}
                        />
                        {errors.password && <FormText color="danger"> {errors.password.message} </FormText>}
                      </Col>
                      <Col sm={3}>
                        <Button outline onClick={() => {resetPassword()}}>초기화</Button>
                      </Col>
                    </FormGroup>

                    <FormGroup>
                      <Label>소속 (부서/직책)</Label>
                      <Col>
                      {
                        state.userCompany && 

                        <Controller
                          name="departId"
                          control={control}
                          render={({ field }) => (
                            <Select
                              {...field}
                              classNamePrefix="select2-selection"
                              isSearchable={false}
                              value={state.departList.find((c) => c.value === field.value)}
                              onChange={(val) => field.onChange(val?.value)}
                              options={state.departList}
                            />
                          )}
                        />
                      }

                      {
                        state.userCompany && 
                        
                        <div className="mt-2">
                        <Controller
                          name="positionId"
                          control={control}
                          render={({ field }) => (
                            <Select
                              {...field}
                              classNamePrefix="select2-selection"
                              isSearchable={false}
                              value={state.positionList.find((c) => c.value === field.value)}
                              onChange={(val) => field.onChange(val?.value)}
                              options={state.positionList}
                            />
                          )}
                        />
                        </div>
                      }
                      </Col>
                    </FormGroup>

                    <FormGroup>
                      <Label>직급</Label>
                      <Col>
                      {
                        state.userCompany && 

                        <Controller
                          name="rankId"
                          control={control}
                          render={({ field }) => (
                            <Select
                              {...field}
                              classNamePrefix="select2-selection"
                              isSearchable={false}
                              value={state.rankList.find((c) => c.value === field.value)}
                              onChange={(val) => field.onChange(val?.value)}
                              options={state.rankList}
                            />
                          )}
                        />
                      }
                      </Col>
                    </FormGroup>

                    <FormGroup>
                      <Label>*휴대폰전화</Label>
                      <Col>
                        <Input
                          name="phone"
                          readOnly={true}
                          onChange={formOption.phone.onChange}
                          onBlur={formOption.phone.onBlur}
                          innerRef={formOption.phone.ref}
                          invalid={!!errors.phone}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup>
                      <Label>근무그룹</Label>
                      <Col>
                      {
                        state.userCompany && 

                        <Controller
                          name="groupId"
                          control={control}
                          render={({ field }) => (
                            <Select
                              {...field}
                              classNamePrefix="select2-selection"
                              isSearchable={false}
                              value={state.groupList.find((c) => c.value === field.value)}
                              onChange={(val) => field.onChange(val?.value)}
                              options={state.groupList}
                            />
                          )}
                        />
                      }
                      </Col>
                    </FormGroup>

                    <FormGroup>
                      <Label>사번</Label>
                      <Col>
                        <Input
                          name="companyNumber"
                          readOnly={true}
                          onChange={formOption.companyNumber.onChange}
                          onBlur={formOption.companyNumber.onBlur}
                          innerRef={formOption.companyNumber.ref}
                          invalid={!!errors.companyNumber}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup>
                      <Label>입사일</Label>
                      <Col>
                        <Input
                          name="joinDate"
                          type="date"
                          onChange={formOption.joinDate.onChange}
                          onBlur={formOption.joinDate.onBlur}
                          innerRef={formOption.joinDate.ref}
                          invalid={!!errors.joinDate}
                        />
                      </Col>
                    </FormGroup>

                    {/* <FormGroup tag="fieldset">
                      <legend className="col-form-label">*권한</legend>
                      <Col className="pt-2">
                        {state.roleList.map((role) => (
                          <FormGroup check inline key={`role_key_${role.id}`}>
                            <Input
                              type="radio"
                              name="roleId"
                              value={role.id}
                              onChange={formOption.roleId.onChange}
                              onBlur={formOption.roleId.onBlur}
                              innerRef={formOption.roleId.ref}
                              invalid={!!errors.roleId}
                            />
                            <Label>{role.label}</Label>
                          </FormGroup>
                        ))}
                      </Col>
                    </FormGroup> */}

                    {/* <FormGroup tag="fieldset">
                      <legend className="col-form-label">*재직현황</legend>
                      <Col className="pt-2">
                        <FormGroup check inline>
                          <Input
                            type="radio"
                            name="state"
                            value="SUCCESS"
                            onChange={formOption.state.onChange}
                            onBlur={formOption.state.onBlur}
                            innerRef={formOption.state.ref}
                            invalid={!!errors.state}
                          />
                          <Label>재직</Label>
                        </FormGroup>
                        <FormGroup check inline>
                          <Input
                            type="radio"
                            name="state"
                            value="LEAVE"
                            onChange={formOption.state.onChange}
                            onBlur={formOption.state.onBlur}
                            innerRef={formOption.state.ref}
                            invalid={!!errors.state}
                          />
                          <Label>퇴사</Label>
                        </FormGroup>
                        <FormGroup check inline>
                          <Input
                            type="radio"
                            name="state"
                            value="TEMPORAL_LEAVE"
                            onChange={formOption.state.onChange}
                            onBlur={formOption.state.onBlur}
                            innerRef={formOption.state.ref}
                            invalid={!!errors.state}
                          />
                          <Label>휴직</Label>
                        </FormGroup>
                      </Col>
                    </FormGroup> */}

                    <Row>
                      <Col>
                        <div className="d-flex gap-2 justify-content-center">
                          <Button
                            color="secondary"
                            onClick={() => {
                              onClose(true)
                            }}
                          >
                            최소
                          </Button>
                          <Button type="submit" color="primary">
                            저장
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </>
  )
}

export default UserCompanyEditForm
