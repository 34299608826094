import { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import toastr from "toastr";
import { APIClient } from '../../lib/apiHelper'

let initialState: { [key: string]: any } = {
  options: {
    // labels: ["Series 1", "Series 2", "Series 3", "Series 4", "Series 5"],
    // colors: ["#34c38f", "#556ee6", "#f46a6a", "#50a5f1", "#f1b44c"],
    legend: {
      show: true,
      position: 'bottom',
      horizontalAlign: 'center',
      verticalAlign: 'middle',
      floating: false,
      fontSize: '14px',
      offsetX: 0,
      offsetY: -10,
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            height: 240,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
  },
  series: [
    {
      //name: 'count',
      data: [],
    },
  ],
}

const TopDevice = () => {
  const [state, setState] = useState(initialState)

  useEffect(() => {
    LoadData()
  }, [])

  const LoadData = async () => {
    try {
      const { data: result } = await APIClient.get('/stat/getTopDevicesList')

      console.log('getTopDevicesList:', result)
  
      const labels = result.data.data.map((item: any) => item.model)
      const seriesData = result.data.data.map((item: any) => Number(item.model_count))
      const series = seriesData
  
      console.log('labels:', labels, ' series:', series)
  
      if (result.result) {
        setState((prev: any) => {
          return {
            ...prev,
            options: {
              ...prev.options,
              labels: labels,
              colors: ['#34c38f', '#556ee6', '#f46a6a', '#50a5f1', '#f1b44c'],
            },
            series: series,
          }
        })
      }
    } catch (error: any) {
      toastr.error(error.message)
    }
  }

  return (
    <>
      {state.series.length > 0 ? (
        <ReactApexChart options={state.options} series={state.series} type="donut" height="380" />
      ) : (
        <div style={{ width: '100%', height: 345 }} className="d-flex align-items-center justify-content-center">
          no data
        </div>
      )}
    </>
  )
}

export default TopDevice
