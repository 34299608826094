import React, { Component } from "react"

import { Link } from "react-router-dom"

import SidebarContent from "./SidebarContent"

type SidebarProps = {
  // type: string,
  // theme: string
  isMobile: boolean
}

const Sidebar = (props: SidebarProps) => {

  return (
    <React.Fragment>

      <div className="vertical-menu">
        <div className="navbar-brand-box">
          <Link to="/" className="logo logo-dark">
            <span className="logo-sm fs-1 fw-bold">
              {/* <img src={logo} alt="" height="22" /> */}
              {/* Pinat Back Office */}
              P
            </span>
            <span className="logo-lg fs-4 fw-bolde">
              {/* <img src={logoDark} alt="" height="17" /> */}
              Pinat Back Office
            </span>
          </Link>

          <Link to="/" className="logo logo-light">
            {/* <span className="logo-sm">
              <img src={logoLightSvg} alt="" height="22" />
            </span>
            <span className="logo-lg">
              <img src={logoLightPng} alt="" height="19" />
            </span> */}
            <span className="logo-sm fs-1 fw-bold text-white">
              {/* <img src={logo} alt="" height="22" /> */}
              {/* Pinat Back Office */}
              P
            </span>
            <span className="logo-lg fs-4 fw-bold text-white">
              {/* <img src={logoDark} alt="" height="17" /> */}
              Pinat Back Office
            </span>
          </Link>
        </div>
        <div data-simplebar className="h-100">
          {/* {props.type !== "condensed" ? (
            <SidebarContent type={props.type} />
          ) : (
            <SidebarContent type={props.type} />
          )} */}

          <SidebarContent  />
        </div>
        <div className="sidebar-background"></div>
      </div>
    </React.Fragment>
  )  
}


export default Sidebar