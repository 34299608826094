import React, { Component, useEffect, useState } from 'react'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { Link } from 'react-router-dom'

//i18n
import { useTranslation } from 'react-i18next'

// users
import user1 from "../../../assets/images/users/avatar-1.jpg";
import { useAuth } from '../../../context/AuthContext'

type ProfileMenuProp = {
  success?: string
}

const getUserName = () => {
  if (localStorage.getItem('authUser')) {
    const obj = JSON.parse(localStorage.getItem('authUser') as string)
    return obj
  }
}

const ProfileMenu = (props: ProfileMenuProp) => {
  const { t } = useTranslation()
  const { isAuthenticated, user } = useAuth()
  const [state, setState] = useState({
    menu: false,
    name: 'Admin',
  })

  const toggle = () => {
    setState((prevState: any) => ({
      ...prevState,
      menu: !prevState.menu,
    }))
  }

  useEffect(() => {
    // const userData = getUserName();
    // if (userData) {
    //   setState({ ...state, name: userData.username })
    // }
  }, [])

  // componentDidUpdate(prevProps) {
  //   if (prevProps.success !== this.props.success) {
  //     const userData = getUserName();
  //     if (userData) {
  //       this.setState({ name: userData.username })
  //     }
  //   }
  // }

  return (
    <React.Fragment>
      <Dropdown isOpen={state.menu} toggle={toggle} className="d-inline-block">
        <DropdownToggle className="btn header-item" id="page-header-user-dropdown" tag="button">
        {/* <img
            className="rounded-circle header-profile-user"
            src={user1}
            alt="Header Avatar"
          /> */}

          {user && (
            <>
              {user.profile ? (
                <div className="d-inline-block">
                  <img className="rounded-circle avatar-xs header-profile-user" src={user.profile}  />
                </div>
              ) : (
                <div className="rounded-circle header-profile-user d-inline-block">
                  <span className="avatar-title rounded-circle">{user.name.charAt(0)}</span>
                </div>
              )}
            </>
          )}
          <span className="d-none d-xl-inline-block ms-1">{user?.name}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <Link to="/profile" className="dropdown-item">
            <i className="bx bx-user font-size-16 align-middle ms-1" />
            <span>{t('Profile')}</span>
          </Link>
          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{t('Logout')}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

export default ProfileMenu
