import { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import toastr from "toastr";
import { APIClient } from '../../lib/apiHelper'

let initialState: { [key: string]: any } = {
  options: {
    chart: { zoom: { enabled: false }, toolbar: { show: false } },
    colors: ['#556ee6'],
    dataLabels: { enabled: true },
    stroke: { show: true, width: [3], curve: 'straight' },
    grid: {
      row: { colors: ['transparent'], opacity: 0.2 },
      borderColor: '#f1f1f1',
    },
    markers: { style: 'inverted', size: 6 },
    xaxis: {
      categories: [],
      //title: {text: 'date'}
    },
    //yaxis: { title: { text: "Temperature" }},
    legend: {
      show: true,
      position: 'top',
      floating: true,
      // position: "top",
      horizontalAlign: 'right',
      // floating: !0,
      offsetY: -25,
      offsetX: -5,
    },
    responsive: [
      {
        breakpoint: 600,
        options: { legend: { show: false } },
      },
    ],
  },
  series: [
    {
      name: 'count',
      data: [],
    },
  ],
}

const DailyTraffic = () => {
  const [state, setState] = useState(initialState)

  useEffect(() => {
    loadData()
  }, [])

  const loadData = async () => {
    try {
      const { data: result } = await APIClient.get('/stat/getDailyTrafficList')

      console.log('getDailyTrafficList:', result)
  
      const categories = result.data.data.map((item: any) => item.dt)
      const seriesData = result.data.data.map((item: any) => Number(item.count))
      const series = [
        {
          name: 'count',
          data: seriesData,
        },
      ]
  
      console.log('categories:', categories, ' series:', series)
  
      if (result.result) {
        setState((prev: any) => {
          return {
            ...prev,
            options: {
              ...prev.options,
              xaxis: {
                ...prev.options.xaxis,
                categories: categories,
              },
            },
            series: series,
          }
        })
      }
    } catch (error: any) {
      toastr.error(error.message)
    }
  }

  return (
    <>
      {state.series.length > 0 ? (
        <ReactApexChart options={state.options} series={state.series} type="line" height="300" />
      ) : (
        <div style={{ width: '100%', height: 315 }} className="d-flex align-items-center justify-content-center">no data</div>
      )}
    </>
  )
}

export default DailyTraffic
