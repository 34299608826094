import React, { ChangeEvent, ChangeEventHandler, useEffect, useRef, useState } from 'react'
import { Link, useLocation, createSearchParams, useNavigate } from 'react-router-dom'
import MetaTags from 'react-meta-tags'
import queryString from 'query-string'
import moment from 'moment'
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
} from 'reactstrap'
import { APIClient } from '../../lib/apiHelper'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import DailyUser from '../../components/analytics/DailyUser'
import TopCompany from '../../components/analytics/TopCompany'

const UserAnalytics = () => {
  return (
    <div className="page-content">
      <MetaTags>
        <title>회원 분석</title>
      </MetaTags>

      <Container fluid>
        {/* Render Breadcrumbs */}
        <Breadcrumbs title="" breadcrumbItem="회원 분석" />

        <Row>
          <Col md="6">
            <Card>
              <CardBody>
                <CardTitle className="mb-2">신규회원 분석 (일/월)</CardTitle>

                <DailyUser />                
              </CardBody>
            </Card>
          </Col>
          <Col md="6">
            <Card>
              <CardBody>
                <CardTitle className="mb-2">전체회원 분석 (일/월)</CardTitle>
                
                <div style={{ width: '100%', height: 315 }} className="d-flex align-items-center justify-content-center">no data</div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md="6">
            <Card>
              <CardBody>
                <CardTitle className="mb-2">회사별 이용율 순위</CardTitle>
                <TopCompany />
              </CardBody>
            </Card>
          </Col>
          <Col md="6">
            
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default UserAnalytics
