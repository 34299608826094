import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation, createSearchParams, useNavigate } from 'react-router-dom'
import MetaTags from 'react-meta-tags'
import { Card, CardBody, Col, Container, Row, Button, Form, FormGroup, Label, Input, FormText, FormFeedback } from 'reactstrap'
import toastr from 'toastr'
import { useForm } from 'react-hook-form'
import { APIClient } from '../../lib/apiHelper'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import SweetAlert from 'react-bootstrap-sweetalert'

const CompanyAddForm = () => {
  const navigate = useNavigate()
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const [alert, setAlert] = useState<any>(null)

  useEffect(() => {
    setInitialValue()
  }, [])

  const setInitialValue = () => {
    reset({
      planId: "1",
      adminState: 'SUCCESS',
    })
  }

  const onSubmit = async (data: any) => {
    console.log('onSubmit', data)

    const params = {
      name: data.name,
      ceoName: data.ceoName,
      planId: data.planId,
      registrationNumber: data.registrationNumber,
      tel: data.tel,
      contractStartAd: data.contractStartAd,
      contractEndAd: data.contractEndAd,

      admin: {
        email: data.adminEmail,
        name: data.adminName,
        password: data.adminPassword,
        phone: data.adminPhone,
        state: data.adminState,
      },
    }

    try {
      const { data: result } = await APIClient.post(`/company`, params)

      if (result.result) {
        toastr.success('회사 등록 되었습니다')

        navigate('/company')
      }
    } catch (error: any) {
      toastr.error(error.message)
    }
  }

  const onError = (d: any) => {
    console.error('error', d)
  }

  const validateEmail = async (v: string) => {
    console.log('v:', v)
    if (!v) {
      return true
    }

    try {
      if (v) {
        const { data: result } = await APIClient.get(`/userCompany/searchId?email=${v}`)

        console.log('result:', result)

        if (result.result) {
          return result.data.count === 0 || '사용 불가능한 이메일입니다'
        }
      }
    } catch (error: any) {
      toastr.error(error.message)
    }

    return false
  }

  const formOption = {
    name: register('name', { required: true, maxLength: { value: 15, message: '최대 15자' } }),
    ceoName: register('ceoName', { required: true, maxLength: { value: 15, message: '한글, 영문(최대15자)' } }),
    planId: register('planId', { required: true }),

    registrationNumber: register('registrationNumber', { required: true, maxLength: { value: 10, message: '최대 10자' } }),
    tel: register('tel', { required: true, maxLength: { value: 11, message: '최대 11자' } }),
    contractStartAt: register('contractStartAt', { required: false }),
    contractEndAt: register('contractEndAt', { required: false }),

    adminEmail: register('adminEmail', {
      required: false,
      maxLength: { value: 33, message: '최대 33자' },
      pattern: { value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, message: '유효한 이메일을 입력해주세요' },
      validate: validateEmail,
    }),
    adminName: register('adminName', { required: false, maxLength: { value: 15, message: '한글, 영문(최대15자)' } }),
    adminPassword: register('adminPassword', {
      required: false,
      pattern: { value: /^(?=.*\d)(?=.*[a-zA-Z]).{8,16}$/, message: '숫자, 영문 혼합 (최소8~최대 16자)' },
    }),
    adminPhone: register('adminPhone', { required: false, maxLength: { value: 11, message: '최대 11자' } }),
    adminState: register('adminState', { required: false }),
  }

  const cancel = () => {
    const getAlert = () => (
      <SweetAlert
        title=""
        warning
        showCancel
        confirmBtnText="확인"
        cancelBtnText="취소"
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        onConfirm={() => {
          setAlert(null)
          navigate(-1)
        }}
        onCancel={() => setAlert(null)}
      >
        작업을 취소하시겠니까?
      </SweetAlert>
    )

    setAlert(getAlert())
  }

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>회사 등록</title>
        </MetaTags>

        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="회사관리" breadcrumbItem="회사 등록" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Form onSubmit={handleSubmit(onSubmit, onError)}>
                    <Row>
                      <Col xs={12} md={6}>
                        <FormGroup row>
                          <Label sm={4}>*회사명</Label>
                          <Col sm={8}>
                            <Input
                              name="name"
                              onChange={formOption.name.onChange}
                              onBlur={formOption.name.onBlur}
                              innerRef={formOption.name.ref}
                              invalid={!!errors.name}
                            />
                            {errors.name && <FormText color="danger"> {errors.name.message} </FormText>}
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Label sm={4}>*대표자명</Label>
                          <Col sm={8}>
                            <Input
                              name="ceoName"
                              onChange={formOption.ceoName.onChange}
                              onBlur={formOption.ceoName.onBlur}
                              innerRef={formOption.ceoName.ref}
                              invalid={!!errors.ceoName}
                            />
                            {errors.ceoName && <FormText color="danger"> {errors.ceoName.message} </FormText>}
                          </Col>
                        </FormGroup>

                        <FormGroup row tag="fieldset">
                          <legend className="col-form-label col-sm-4">*이용요금</legend>
                          <Col sm={8} className="pt-2">
                            <FormGroup check inline>
                              <Input
                                type="radio"
                                name="planId"
                                value="1"
                                onChange={formOption.planId.onChange}
                                onBlur={formOption.planId.onBlur}
                                innerRef={formOption.planId.ref}
                                invalid={!!errors.planId}
                              />
                              <Label>무료</Label>
                            </FormGroup>
                            <FormGroup check inline>
                              <Input
                                type="radio"
                                name="planId"
                                value="2"
                                onChange={formOption.planId.onChange}
                                onBlur={formOption.planId.onBlur}
                                innerRef={formOption.planId.ref}
                                invalid={!!errors.planId}
                              />
                              <Label>유료1</Label>
                            </FormGroup>
                            <FormGroup check inline>
                              <Input
                                type="radio"
                                name="planId"
                                value="3"
                                onChange={formOption.planId.onChange}
                                onBlur={formOption.planId.onBlur}
                                innerRef={formOption.planId.ref}
                                invalid={!!errors.planId}
                              />
                              <Label>유료2</Label>
                            </FormGroup>
                            <FormGroup check inline>
                              <Input
                                type="radio"
                                name="planId"
                                value="4"
                                onChange={formOption.planId.onChange}
                                onBlur={formOption.planId.onBlur}
                                innerRef={formOption.planId.ref}
                                invalid={!!errors.planId}
                              />
                              <Label>유료3</Label>
                            </FormGroup>
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Label sm={4}>최고관리자ID</Label>
                          <Col sm={8}>
                            <Input
                              name="adminEmail"
                              type="email"
                              onChange={formOption.adminEmail.onChange}
                              onBlur={formOption.adminEmail.onBlur}
                              innerRef={formOption.adminEmail.ref}
                              invalid={!!errors.adminEmail}
                            />
                            {errors.adminEmail && <FormText color="danger"> {errors.adminEmail.message} </FormText>}
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label sm={4}>최고관리자 이름</Label>
                          <Col sm={8}>
                            <Input
                              name="adminName"
                              onChange={formOption.adminName.onChange}
                              onBlur={formOption.adminName.onBlur}
                              innerRef={formOption.adminName.ref}
                              invalid={!!errors.adminName}
                            />
                            {errors.adminName && <FormText color="danger"> {errors.adminName.message} </FormText>}
                          </Col>
                        </FormGroup>

                        <FormGroup row tag="fieldset">
                          <legend className="col-form-label col-sm-4">최고관리자 현황</legend>
                          <Col sm={8} className="pt-2">
                            <FormGroup check inline>
                              <Input
                                type="radio"
                                name="adminState"
                                value="SUCCESS"
                                onChange={formOption.adminState.onChange}
                                onBlur={formOption.adminState.onBlur}
                                innerRef={formOption.adminState.ref}
                                invalid={!!errors.adminStatus}
                              />
                              <Label>재직</Label>
                            </FormGroup>
                            <FormGroup check inline>
                              <Input
                                type="radio"
                                name="adminState"
                                value="LEAVE"
                                onChange={formOption.adminState.onChange}
                                onBlur={formOption.adminState.onBlur}
                                innerRef={formOption.adminState.ref}
                                invalid={!!errors.adminStatus}
                              />
                              <Label>퇴사</Label>
                            </FormGroup>
                            <FormGroup check inline>
                              <Input
                                type="radio"
                                name="adminState"
                                value="TEMPORAL_LEAVE"
                                onChange={formOption.adminState.onChange}
                                onBlur={formOption.adminState.onBlur}
                                innerRef={formOption.adminState.ref}
                                invalid={!!errors.adminStatus}
                              />
                              <Label>휴직</Label>
                            </FormGroup>
                          </Col>
                        </FormGroup>
                      </Col>
                      <Col xs={12} md={6}>
                        <FormGroup row>
                          <Label sm={4}>*사업자번호</Label>
                          <Col sm={8}>
                            <Input
                              name="registrationNumber"
                              onChange={formOption.registrationNumber.onChange}
                              onBlur={formOption.registrationNumber.onBlur}
                              innerRef={formOption.registrationNumber.ref}
                              invalid={!!errors.registrationNumber}
                            />
                            {errors.registrationNumber && <FormText color="danger"> {errors.registrationNumber.message} </FormText>}
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label sm={4}>*회사연락처</Label>
                          <Col sm={8}>
                            <Input
                              name="tel"
                              onChange={formOption.tel.onChange}
                              onBlur={formOption.tel.onBlur}
                              innerRef={formOption.tel.ref}
                              invalid={!!errors.tel}
                            />
                            {errors.tel && <FormText color="danger"> {errors.tel.message} </FormText>}
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label sm={4}>계약기간</Label>
                          <Col sm={8}>
                            <div className="d-flex flex-row gap-3">
                              <Input
                                name="contractStartAt"
                                type="date"
                                onChange={formOption.contractStartAt.onChange}
                                onBlur={formOption.contractStartAt.onBlur}
                                innerRef={formOption.contractStartAt.ref}
                                invalid={!!errors.contractStartAt}
                              />
                              <div className="d-flex align-items-center">
                                <span>~</span>
                              </div>
                              <Input
                                name="contractEndAt"
                                type="date"
                                onChange={formOption.contractEndAt.onChange}
                                onBlur={formOption.contractEndAt.onBlur}
                                innerRef={formOption.contractEndAt.ref}
                                invalid={!!errors.contractEndAt}
                              />
                            </div>
                          </Col>
                          {/* <Col sm={4}>
                            
                          </Col> */}
                        </FormGroup>
                        <FormGroup row>
                          <Label sm={4}>최고관리자PW</Label>
                          <Col sm={8}>
                            <Input
                              name="adminPassword"
                              type="password"
                              onChange={formOption.adminPassword.onChange}
                              onBlur={formOption.adminPassword.onBlur}
                              innerRef={formOption.adminPassword.ref}
                              invalid={!!errors.adminPassword}
                            />
                            {errors.adminPassword && <FormText color="danger"> {errors.adminPassword.message} </FormText>}
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label sm={4}>최고관리자 연락처</Label>
                          <Col sm={8}>
                            <Input
                              name="adminPhone"
                              onChange={formOption.adminPhone.onChange}
                              onBlur={formOption.adminPhone.onBlur}
                              innerRef={formOption.adminPhone.ref}
                              invalid={!!errors.adminPhone}
                            />
                            {errors.adminPhone && <FormText color="danger"> {errors.adminPhone.message} </FormText>}
                          </Col>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <div className="d-flex gap-2 justify-content-center">
                          <Button
                            color="secondary"
                            onClick={() => {
                              cancel()
                            }}
                          >
                            최소
                          </Button>
                          <Button type="submit" color="primary">
                            저장
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {alert}
    </>
  )
}

export default CompanyAddForm
