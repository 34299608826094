import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation, createSearchParams, useNavigate } from 'react-router-dom'
import {
  Card,
  CardBody,
  Col,
  Row,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  FormFeedback,
  Media,
} from 'reactstrap'
import { useForm, Controller } from 'react-hook-form'
import toastr from "toastr";
import Select from 'react-select'
import { APIClient } from '../../lib/apiHelper'

interface AdminEditFormProps {
  selectedId: number | null
  adminRoleList: any[]
  onClose: (reload: boolean) => void
}

const AdminUserEditForm = ({ selectedId, onClose, adminRoleList }: AdminEditFormProps) => {
  const navigate = useNavigate()
  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm()

  const [state, setState] = useState({
    adminUser: null as any,
  })

  const [changePassword, setChangePassword] = useState(false)

  const formOption = {
    email: register('email', { required: true }),
    name: register('name', { required: true, maxLength: { value: 15, message: '한글, 영문(최대15자)' } }),
    phone: register('phone', { required: true, maxLength: { value: 11, message: '최대 11자' } }),
    password: register('password', {
      required: true,
      pattern: {value: /^(?=.*\d)(?=.*[a-zA-Z]).{8,16}$/, message: '숫자, 영문 혼합 (최소8~최대 16자)'}
    }),

    companyName: register('companyName', { required: true, maxLength: { value: 15, message: '최대 15자' }}),
    departName: register('departName', { required: false, maxLength: { value: 15, message: '최대 15자' }}),
    rankName: register('rankName', { required: false, maxLength: { value: 15, message: '최대 15자' }}),
    adminRoleId: register('adminRoleId', { required: true }),
    
    profileFile: register('profileFile', { required: false }),    
  }

  useEffect(() => {
    if (selectedId) {
      loadDetail(selectedId)
    }
  }, [selectedId])

  const loadDetail = async (id: number) => {
    try {
      const { data: result } = await APIClient.get(`/adminUser/${id}`)

      if (result.result) {
        const adminUser = result.data.adminUser
  
        setState({
          ...state,
          adminUser,
        })
  
        //set form data
        const values = {
          email: adminUser.email,
          password: '________a1',
          name: adminUser.name,
          phone: adminUser.phone,
  
          companyName: adminUser.companyName,
          departName: adminUser.departName,
          rankName: adminUser.rankName,
          adminRoleId: adminUser.adminRoleId,
        }
  
        console.log('values:', values)
  
        reset(values)
      }
    } catch (error: any) {
      toastr.error(error.message)
    }
  }

  const onSubmit = async (data: any) => {
    console.log('onSubmit', data)

    const params = {
      name: data.name,
      password: data.password,
      phone: data.phone,

      companyName: data.companyName,
      departName: data.departName,
      rankName: data.rankName,
      adminRoleId: data.adminRoleId,
    }

    console.log('params', params)

    const formData = new FormData()
    formData.append('name', data.name)
    formData.append('password', data.password)
    formData.append('phone', data.phone)

    for (const file of data.profileFile) {
      formData.append('profileFile', file)  
    }

    formData.append('companyName', data.companyName)
    formData.append('departName', data.departName)
    formData.append('rankName', data.rankName)
    formData.append('adminRoleId', data.adminRoleId)
    formData.append('profile', state.adminUser.profile || '')

    console.log('formData', formData)

    try {
      const { data: result } = await APIClient.put(`/adminUser/${state.adminUser.id}`, formData, {
        'Content-Type': 'multipart/form-data'
      })

      if (result.result) {
        toastr.success('운영자 정보 수정 되었습니다')
  
        onClose(true)
      }
    } catch (error: any) {
      toastr.error(error.message)
    }
  }

  const handleProfile = () => {
    setState((prev: any) => {
      return {
        ...prev,
        adminUser: {
          ...prev.adminUser,
          profile: ''
        }
      }
    })
  }

  const onError = (d: any) => {
    console.error('error', d)
  }

  const resetPassword = () => {
    setChangePassword(true)

    setValue('password', '')
  }

  return (
    <>
      {state.adminUser && (
        <>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  
                  <Form onSubmit={handleSubmit(onSubmit, onError)}>
                  <Row>
                    <Col>
                      <Media className="mb-5">
                        <div className="me-3">
                          {/* <img src={avatar} alt="" className="avatar-md rounded-circle img-thumbnail" /> */}
                          {!state.adminUser?.profile ? (
                            <div className="avatar-md">
                              <span className="avatar-title rounded-circle">{state.adminUser.name.charAt(0)}</span>
                            </div>
                          ) : (
                            <div className="d-flex flex-column align-items-center">
                              <img className="rounded-circle avatar-md" src={state.adminUser.profile} alt={state.adminUser.name} />
                              <Link className="text-danger" to="#">
                                <i
                                  className="mdi mdi-delete font-size-18"
                                  id="deletetooltip"
                                  onClick={(e) => {
                                    e.preventDefault()
                                    handleProfile()
                                  }}
                                ></i>
                              </Link>
                            </div>
                          )}
                        </div>
                        <div className="mt-3">
                          <Input
                              name="profileFile"
                              id="formFile"
                              type="file"
                              onChange={formOption.profileFile.onChange}
                              onBlur={formOption.profileFile.onBlur}
                              innerRef={formOption.profileFile.ref}
                              invalid={!!errors.profileFile}
                            />

                        </div>
                      </Media>
                    </Col>
                  </Row>
                    <FormGroup>
                      <Label>*아이디(이메일)</Label>
                      <Row>
                        <Col>
                          <Input
                            name="email"
                            type="email"
                            readOnly={true}
                            onChange={formOption.email.onChange}
                            onBlur={formOption.email.onBlur}
                            innerRef={formOption.email.ref}
                            invalid={!!errors.email}
                          />                          
                        </Col>
                      </Row>
                    </FormGroup>

                    <FormGroup row>
                      <Label>*비밀번호</Label>
                      <Col sm={9}>
                        <Input
                          name="password"
                          type="password"
                          readOnly={!changePassword}
                          onChange={formOption.password.onChange}
                          onBlur={formOption.password.onBlur}
                          innerRef={formOption.password.ref}
                          invalid={!!errors.password}
                        />
                        {errors.password && <FormText color="danger"> {errors.password.message} </FormText>}
                      </Col>
                      <Col sm={3}>
                        <Button type="button" outline onClick={() => {resetPassword()}}>초기화</Button>
                      </Col>
                    </FormGroup>
                    <FormGroup>
                      <Label>*이름</Label>
                      <Col>
                        <Input
                          name="name"
                          onChange={formOption.name.onChange}
                          onBlur={formOption.name.onBlur}
                          innerRef={formOption.name.ref}
                          invalid={!!errors.name}
                        />
                        {errors.name && <FormText color="danger"> {errors.name.message} </FormText>}
                      </Col>
                    </FormGroup>
                    <FormGroup>
                      <Label>*회사명</Label>
                      <Col>
                        <Input
                          name="companyName"
                          onChange={formOption.companyName.onChange}
                          onBlur={formOption.companyName.onBlur}
                          innerRef={formOption.companyName.ref}
                          invalid={!!errors.companyName}
                        />
                        {errors.companyName && <FormText color="danger"> {errors.companyName.message} </FormText>}
                      </Col>
                    </FormGroup>
                    <FormGroup>
                      <Label>*연락처</Label>
                      <Col>
                        <Input
                          name="phone"
                          onChange={formOption.phone.onChange}
                          onBlur={formOption.phone.onBlur}
                          innerRef={formOption.phone.ref}
                          invalid={!!errors.phone}
                        />
                        {errors.phone && <FormText color="danger"> {errors.phone.message} </FormText>}
                      </Col>
                    </FormGroup>
                    <FormGroup>
                      <Label>부서</Label>
                      <Col>
                        <Input
                          name="departName"
                          onChange={formOption.departName.onChange}
                          onBlur={formOption.departName.onBlur}
                          innerRef={formOption.departName.ref}
                          invalid={!!errors.departName}
                        />
                        {errors.departName && <FormText color="danger"> {errors.departName.message} </FormText>}
                      </Col>                      
                    </FormGroup>
                    <FormGroup>
                      <Label>직급</Label>
                      <Col>
                        <Input
                          name="rankName"
                          onChange={formOption.rankName.onChange}
                          onBlur={formOption.rankName.onBlur}
                          innerRef={formOption.rankName.ref}
                          invalid={!!errors.rankName}
                        />
                        {errors.rankName && <FormText color="danger"> {errors.rankName.message} </FormText>}
                      </Col>                      
                    </FormGroup>
                    <FormGroup>
                      <Label>*권한</Label>
                      <Col>
                        <Controller
                          name="adminRoleId"
                          control={control}
                          render={({ field }) => (
                            <Select
                              {...field}
                              classNamePrefix="select2-selection"
                              value={adminRoleList.find((c) => c.value === field.value)}
                              onChange={(val) => field.onChange(val?.value)}
                              options={adminRoleList}
                            />
                          )}
                        />
                      </Col>
                    </FormGroup>

                    <Row>
                      <Col>
                        <div className="d-flex gap-2 justify-content-center">
                          <Button
                            color="secondary"
                            onClick={() => {
                              onClose(true)
                            }}
                          >
                            최소
                          </Button>
                          <Button type="submit" color="primary">
                            저장
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </>
  )
}

export default AdminUserEditForm
