import { Ability, AbilityBuilder, PureAbility } from "@casl/ability";

const defineAbility = () => {
  const { can, rules } = new AbilityBuilder(PureAbility);

  //default empty ability
  const ability = new PureAbility(rules)

  return ability
}

export const updateAbility = (ability: PureAbility, user: any) => {
  const { can, rules } = new AbilityBuilder(PureAbility);

  if (!user) {
    return ability
  }

  //populate ability
  const permissionList = user.adminRole.permission

  //console.log('perms:', permissionList)

  for (const perm of permissionList) {
    const subject = perm.split(':')[0]
    const action = perm.split(':')[1]

    can(action, subject)
  }

  ability.update(rules);
}


const ability = defineAbility()

export default ability