import React, { ChangeEvent, ChangeEventHandler, useEffect, useRef, useState } from 'react'
import { Link, useLocation, createSearchParams, useNavigate } from 'react-router-dom'
import MetaTags from 'react-meta-tags'
import queryString from 'query-string'
import moment from 'moment'
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledButtonDropdown,
  Input,
} from 'reactstrap'
import { APIClient } from '../../lib/apiHelper'
import Breadcrumbs from '../../components/Common/Breadcrumb'

const Dashboard = () => {
  return (
    <div className="page-content">
      <MetaTags>
        <title>대시보드</title>
      </MetaTags>

      <Container fluid>
        {/* Render Breadcrumbs */}
        <Breadcrumbs title="" breadcrumbItem="대시보드" />

        <Row>
          <Col lg="12"></Col>
        </Row>
      </Container>
    </div>
  )
}

export default Dashboard
