import { Base64 } from 'js-base64'

export const setItem = (key: string, value: string) => {
	localStorage.setItem(Base64.encode(key), Base64.encode(value))
}

export const getItem = (key: string) => {
	const encKey = Base64.encode(key)
	const encItem = localStorage.getItem(encKey)

	return encItem ? Base64.decode(encItem) : null
}

export const removeItem = (key: string) => {
	localStorage.removeItem(Base64.encode(key))
}
