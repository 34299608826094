import React, { ChangeEvent, ChangeEventHandler, useEffect, useRef, useState } from 'react'
import { Link, useLocation, createSearchParams, useNavigate } from 'react-router-dom'
import MetaTags from 'react-meta-tags'
import queryString from 'query-string'
import moment from 'moment'
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  CardTitle,
} from 'reactstrap'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import DailyTraffic from '../../components/analytics/DailyTraffic'
import TopDevice from '../../components/analytics/TopDevice'

const TrafficAnalytics = () => {
  

  return (
    <div className="page-content">
      <MetaTags>
        <title>방문자 분석</title>
      </MetaTags>

      <Container fluid>
        {/* Render Breadcrumbs */}
        <Breadcrumbs title="" breadcrumbItem="방문자 분석" />

        <Row>
          <Col md="6">
            <Card>
              <CardBody>
                <CardTitle className="mb-2">실시간 접속자 현황</CardTitle>

                <DailyTraffic />
              </CardBody>
            </Card>
          </Col>
          <Col md="6">
            <Card>
              <CardBody>
                <CardTitle className="mb-2">사용자 통계(일/월)</CardTitle>

                <div style={{ width: '100%', height: 315 }} className="d-flex align-items-center justify-content-center">no data</div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md="6">
            <Card>
              <CardBody>
                <CardTitle className="mb-2">접속환경</CardTitle>
                <TopDevice />
              </CardBody>
            </Card>
          </Col>
          <Col md="6">
            <Card>
              <CardBody>
                <CardTitle className="mb-2">유입경로</CardTitle>
                <div style={{ width: '100%', height: 345 }} className="d-flex align-items-center justify-content-center">no data</div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default TrafficAnalytics
