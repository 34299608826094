import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";

const Logout = () => {
  let navigate = useNavigate();
  let {logout} = useAuth();

  useEffect(() => {
    logout(() => {
      navigate("/")
    })
  }, [])

  return (
    <></>
  );
}

export default Logout