import React, { Component, useEffect, useState } from 'react'

// Layout Related Components
import Header from './Header'
import Sidebar from './Sidebar'
import Footer from './Footer'
import { Outlet } from 'react-router-dom'

type LayoutProps = {
  // changeLayoutWidth: (v: string) => void,
  // changeSidebarTheme: (v: string) => void,
  // changeSidebarThemeImage: (v: string) => void,
  // changeSidebarType: (v: string) => void,
  // changeTopbarTheme: (v: string) => void,
  // children: any,
  // isPreloader: boolean,
  // layoutWidth: any,
  // leftSideBarTheme: any,
  // leftSideBarThemeImage: any,
  // leftSideBarType: any,
  // location: object,
  // showRightSidebar: any,
  // toggleRightSidebar: any,
  // topbarTheme: any
}

const Layout = (props: LayoutProps) => {
  const [state, setState] = useState({
    isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
    width: 0,
    height: 0,
  })

  useEffect(() => {
    // if (document) {
    //   document.getElementById("preloader")?.style?.display = "none"
    //   document.getElementById("status")?.style?.display = "none"
    // }

    document.body.setAttribute('data-layout', 'vertical')

    document.body.setAttribute('data-sidebar', 'dark')

    document.body.setAttribute('data-sidebar-image', 'none')

    document.body.setAttribute('data-layout-size', 'fluid')

    document.body.setAttribute('data-layout-scrollable', 'false')

    document.body.setAttribute('data-sidebar-size', '')
    document.body.classList.remove('sidebar-enable')
    document.body.classList.remove('vertical-collpsed')

    document.body.setAttribute('data-topbar', 'light')
  }, [])

  // useEffect(() => {
  //   // if (props.isPreloader === true) {
  //   //   document.getElementById("preloader").style.display = "block"
  //   //   document.getElementById("status").style.display = "block"

  //   //   setTimeout(function () {
  //   //     document.getElementById("preloader").style.display = "none"
  //   //     document.getElementById("status").style.display = "none"
  //   //   }, 2500)
  //   // } else {
  //   //   document.getElementById("preloader").style.display = "none"
  //   //   document.getElementById("status").style.display = "none"
  //   // }

  //   // Scroll Top to 0
  //   window.scrollTo(0, 0)
  //   // let currentage = capitalizeFirstLetter(props.location.pathname)

  //   // document.title =
  //   //   currentage + " | Skote - React Admin & Dashboard Template"
  //   if (props.leftSideBarTheme) {
  //     props.changeSidebarTheme(props.leftSideBarTheme)
  //   }

  //   if (props.leftSideBarThemeImage) {
  //     props.changeSidebarThemeImage(props.leftSideBarThemeImage)
  //   }

  //   if (props.layoutWidth) {
  //     props.changeLayoutWidth(props.layoutWidth)
  //   }

  //   if (props.leftSideBarType) {
  //     props.changeSidebarType(props.leftSideBarType)
  //   }
  //   if (props.topbarTheme) {
  //     props.changeTopbarTheme(props.topbarTheme)
  //   }

  //   if (props.showRightSidebar) {
  //     props.toggleRightSidebar()
  //   }
  // }, [])

  const toggleMenuCallback = () => {
    var body = document.body
    if (window.screen.width <= 998) {
      body.classList.toggle('sidebar-enable')
    } else {
      body.classList.toggle('vertical-collpsed')
      body.classList.toggle('sidebar-enable')
    }
  }

  return (
    <React.Fragment>
      <div id="preloader" style={{ display: 'none' }}>
        <div id="status" style={{ display: 'none' }}>
          <div className="spinner-chase">
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
          </div>
        </div>
      </div>

      <div id="layout-wrapper" style={{ display: 'block' }}>
        <Header
          toggleMenuCallback={toggleMenuCallback}
          // toggleRightSidebar={props.toggleRightSidebar}
        />
        <Sidebar
          // theme={props.leftSideBarTheme}
          // type={props.leftSideBarType}
          isMobile={state.isMobile}
        />
        <div className="main-content">
          <Outlet />
        </div>
        <Footer />
      </div>
    </React.Fragment>
  )
}

export default Layout
