import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation, createSearchParams, useNavigate, useParams } from 'react-router-dom'
import MetaTags from 'react-meta-tags'
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  FormFeedback,
} from 'reactstrap'
import toastr from "toastr";
import { useForm } from 'react-hook-form'
import { APIClient } from '../../lib/apiHelper'
import Breadcrumbs from '../../components/Common/Breadcrumb'

const ServiceQuestionDetail = () => {
  const navigate = useNavigate()
  const locationParams = useParams()

  const [state, setState] = useState({
    question: null as any,
  })

  useEffect(() => {
    if (locationParams.questionId) {
      loadDetail(locationParams.questionId)
    }
  }, [locationParams])

  const loadDetail = async (id: string) => {
    try {
      const { data: result } = await APIClient.get(`/board/serviceQuestion/${id}`)

      if (result.result) {
        const question = result.data.question
  
        console.log('question:', question)
  
        setState((prev: any) => {
          return {
            ...prev,
            question: question,
          }
        })
  
      }
    } catch (error: any) {
      toastr.error(error.message)
    }
  }

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>서비스 도입문의 조회</title>
        </MetaTags>

        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="서비스 도입문의" breadcrumbItem={'서비스 도입문의 조회'} />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Form >
                    <Row>
                      <Col xs={12}>
                        <b>기본정보</b>
                      </Col>
                      <Col xs={12}>
                        <FormGroup row>
                          <Label sm={2}>회사명</Label>
                          <Col sm={10}>{state.question?.companyName}</Col>
                        </FormGroup>

                        <FormGroup row>
                          <Label sm={2}>홈페이지</Label>
                          <Col sm={10}>{state.question?.homepage}</Col>
                        </FormGroup>

                        <FormGroup row>
                          <Label sm={2}>업종</Label>
                          <Col sm={10}>{state.question?.companyCategory}</Col>
                        </FormGroup>

                        <FormGroup row>
                          <Label sm={2}>임직원 수</Label>
                          <Col sm={10}>{state.question?.employeeCount}</Col>
                        </FormGroup>
                      </Col>
                      <Col xs={12}>
                        <b>담당자 정보</b>
                      </Col>
                      <Col>
                        <FormGroup row>
                          <Label sm={2}>이름</Label>
                          <Col sm={10}>{state.question?.contactName}</Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label sm={2}>직급/직책</Label>
                          <Col sm={10}>{state.question?.contactRank}</Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label sm={2}>연락처</Label>
                          <Col sm={10}>{state.question?.contactPhone}</Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label sm={2}>이메일</Label>
                          <Col sm={10}>{state.question?.contactEmail}</Col>
                        </FormGroup>
                      </Col>
                      <Col xs={12}>
                        <b>문의 내용</b>
                      </Col>
                      <Col>
                        <FormGroup row>
                          <Label sm={2}>제목</Label>
                          <Col sm={10}>{state.question?.title}</Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label sm={2}>*내용</Label>
                          <Col sm={10}><div dangerouslySetInnerHTML={{ __html: state.question?.content }}></div></Col>                          
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className="mt-3">
                      <Col xs={12} className="mt-3">
                        <div className="d-flex gap-2 justify-content-center">
                          <Button
                            color="secondary"
                            onClick={() => {
                              navigate(-1)
                            }}
                          >
                            확인
                          </Button>
                        </div>                          
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default ServiceQuestionDetail
