export const templateTypeList = [
  {
    name: '회원가입',
    code: 'USER_REGISTRATION',
  },
  {
    name: '회원가입 인증번호',
    code: 'OTP_CODE',
  },
  {
    name: '이용문의',
    code: 'SERVICE_QUESTION',
  },
  {
    name: '휴면전환사전안내',
    code: 'DORMAT_TRANSFER_GUIDE',
  },
  {
    name: '사용자 발송',
    code: 'USER_MESSAGE',
  },
]