import React, { ChangeEvent, ChangeEventHandler, useEffect, useRef, useState } from 'react'
import { Link, useLocation, createSearchParams, useNavigate } from 'react-router-dom'
import MetaTags from 'react-meta-tags'
import queryString from 'query-string'
import moment from 'moment'
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledButtonDropdown,
  Input,
} from 'reactstrap'
import toastr from 'toastr'
import paginationFactory, { PaginationListStandalone, PaginationProvider } from 'react-bootstrap-table2-paginator'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter'
import BootstrapTable from 'react-bootstrap-table-next'
//SweetAlert
import SweetAlert from 'react-bootstrap-sweetalert'
import { APIClient } from '../../lib/apiHelper'
import Breadcrumbs from '../../components/Common/Breadcrumb'

import SentListDetail from './SentListDetail'

const defaultSorted = [
  {
    dataField: 'id', // if dataField is not match to any column you defined, it will be ignored.
    order: 'desc', // desc or asc
  },
]
const keyField = 'id'
const DEFAULT_MAX = 20

const pagingList = [{ max: 20 }, { max: 30 }, { max: 40 }, { max: 50 }, { max: 100 }]

const initialSearchState: { [key: string]: any } = {
  page: 1,
  max: DEFAULT_MAX,
  q: '',
  status: '',
}

const initialState = {
  listData: [],
  total: 0,
  allTotal: 0,
  selectedId: null as number | null,
  selected: [] as any[],
}

interface SearchInputProps {
  onSearch: any
  onClear: any
  searchText: string
  q: string
  onSearchChange: any
}

const SearchInput = ({ onSearch, onClear, searchText, q, onSearchChange }: SearchInputProps) => {
  const [text, setText] = useState('')

  useEffect(() => {
    setText(q)
  }, [q])

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value)
  }

  const handleKeyUp = (e: any) => {
    if (e.charCode === 13) {
      onSearch(text)
      onSearchChange(text)
    }
  }

  return (
    <>
      <Input value={text} onChange={handleChange} onKeyPress={handleKeyUp} />
    </>
  )
}

const SentList = () => {
  const node = useRef()
  const navigate = useNavigate()
  const location = useLocation()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [state, setState] = useState(initialState)
  const [searchState, setSearchState] = useState(initialSearchState)
  const [alert, setAlert] = useState<any>(null)

  const columns = [
    {
      text: '번호',
      dataField: 'id',
      sort: true,
      formatter: (cell: any, row: any) => {
        return <>{row.id}</>
      },
    },
    {
      dataField: 'sendType',
      text: '유형',
      //filter: textFilter(),
      sort: true,
      formatter: (cell: any, row: any) => <span>{row.sendType}</span>,
    },
    {
      dataField: 'point',
      text: '차감포인트',
      sort: true,
      formatter: (cell: any, row: any) => <span>{row.point}</span>,
    },
    {
      dataField: 'templateName',
      text: '템플릿 명',
      //filter: textFilter(),
      sort: true,
      formatter: (cell: any, row: any) => {
        return <span>{row.templateName}</span>
      },
    },
    {
      dataField: 'templateContent',
      text: '템플릿 내용',
      sort: true,
      formatter: (cell: any, row: any) => <span>{row.templateContent}</span>,
    },
    {
      dataField: 'scheduleTime',
      text: '발송(예약)일시',
      sort: true,
      formatter: (cell: any, row: any) => <span>{moment(row.scheduleTime).format('YYYY-MM-DD HH:mm')}</span>,
    },
    {
      dataField: 'count',
      text: '발송건수',
      sort: false,
      formatter: (cell: any, row: any) => (
        <span>
          {row.successCount}/{row.scheduledCount}
        </span>
      ),
    },
    {
      dataField: 'status',
      text: '발송상태',
      sort: false,
      formatter: (cell: any, row: any) => <span>..</span>,
    },
    {
      dataField: 'action',
      isDummyField: true,
      text: '관리',
      editable: false,
      formatter: (cell: any, row: any) => (
        <div className="d-flex gap-3">
          <Link
            className="text-success"
            to="#"
            onClick={(e) => {
              e.preventDefault()
              handleOpenModal(row.id)
            }}
          >
            <i className="mdi mdi-magnify font-size-18" id="edittooltip"></i>
          </Link>
        </div>
      ),
    },
  ]

  useEffect(() => {
    loadData(location.search)
  }, [location.search])

  const loadData = async (search: string) => {
    await loadList(search)
  }

  const loadList = async (search: string) => {
    const query = queryString.parse(search)

    let params = {
      q: query.q || '',
      status: query.status || '',
      page: query.page ? Number(query.page) : 1,
      max: query.max ? Number(query.max) : DEFAULT_MAX,
    }

    console.log('params:', params)

    setSearchState(params)

    try {
      const { data: result } = await APIClient.get(`/allimtalk/sent?${queryString.stringify(params)}`)

      console.log('data:', result.data)

      if (result.result) {
        setState((prevState: any) => {
          return {
            ...prevState,
            listData: result.data.rows,
            total: result.data.count,
            allTotal: result.data.allTotal
          }
        })
      }
    } catch (error: any) {
      toastr.error(error.message)
    }
  }

  const changeUrl = (params: any) => {
    navigate(`/allimtalk/sentList?${queryString.stringify(params)}`)
  }

  const changePageSize = (max: number) => {
    const params = {
      ...searchState,
      max: max,
      page: 1,
    }

    changeUrl(params)
  }

  const onTableChange = (
    type: string,
    {
      page,
      sizePerPage,
      filters,
      sortField,
      sortOrder,
      cellEdit,
    }: { page: number; sizePerPage: number; filters: any; sortField: any; sortOrder: any; cellEdit: any }
  ) => {
    if (type === 'pagination') {
      const params = {
        ...searchState,
        page: page,
      }

      changeUrl(params)
    }
  }

  const onSearchChange = (query: string) => {
    console.log('onSearchChange:', query)

    const params = {
      ...searchState,
      page: 1,
      q: query,
    }

    changeUrl(params)
  }

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
  }

  const handleOpenModal = (id?: number) => {
    console.log('id:', id)

    setState({
      ...state,
      selectedId: id || null,
    })

    setIsModalOpen(true)
  }

  const handleCloseModal = (reload: boolean) => {
    setState({
      ...state,
      selectedId: null,
    })
    setIsModalOpen(false)

    if (reload) {
      //reload
      loadList(location.search)
    }
  }

  const handleDeleteList = () => {
    if (state.selected.length === 0) {
      return
    }

    const getAlert = () => (
      <SweetAlert
        title="정말 삭제 하시겠니까?"
        warning
        showCancel
        confirmBtnText="확인"
        cancelBtnText="취소"
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        onConfirm={() => {
          deleteSentListList()
          setAlert(null)
          console.log('confirm')
        }}
        onCancel={() => setAlert(null)}
      >
        삭제된 발소내역들을 복원할 수 없습니다!
      </SweetAlert>
    )

    setAlert(getAlert())
  }

  const handleDelete = async (id: number) => {
    const getAlert = () => (
      <SweetAlert
        title="정말 삭제 하시겠니까?"
        warning
        showCancel
        confirmBtnText="확인"
        cancelBtnText="취소"
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        onConfirm={() => {
          deleteSentList(id)
          setAlert(null)
          console.log('confirm')
        }}
        onCancel={() => setAlert(null)}
      >
        삭제된 발송내역을 복원할 수 없습니다!
      </SweetAlert>
    )

    setAlert(getAlert())
  }

  const deleteSentList = async (id: number) => {
    try {
      const { data: result } = await APIClient.remove(`/allimtalk/sent/${id}`)

      if (result.result) {
        toastr.success('발송내역이 삭제 되었습니다')

        //reload
        loadList(location.search)
      }
    } catch (error: any) {
      toastr.error(error.message)
    }
  }

  const deleteSentListList = async () => {
    const params = {
      ids: state.selected,
    }

    try {
      const { data: result } = await APIClient.post(`/allimtalk/sent/deleteSentListList`, params)

      if (result.result) {
        toastr.success('선택된 발송내역들이 삭제 되었습니다')

        //reload
        loadList(location.search)
      }
    } catch (error: any) {
      toastr.error(error.message)
    }
  }

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>카카오 알림톡발송 내역</title>
        </MetaTags>

        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="" breadcrumbItem="카카오 알림톡발송 내역" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory({
                      sizePerPage: searchState.max,
                      page: searchState.page,
                      totalSize: state.total, // replace later with size(users),
                      custom: true,
                      //showTotal: true,
                    })}
                    keyField={keyField}
                    columns={columns}
                    data={state.listData}
                  >
                    {({ paginationProps, paginationTableProps }: { paginationProps: any; paginationTableProps: any }) => {
                      return (
                        <ToolkitProvider keyField={keyField} data={state.listData} columns={columns} bootstrap4 search>
                          {(toolkitProps: any) => (
                            <>
                              <Row>
                                <Col sm="4">
                                  <div className="search-box ms-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchInput {...toolkitProps.searchProps} q={searchState.q} onSearchChange={onSearchChange} />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                              <Row className="mb-2">
                                <Col sm="4">
                                  {searchState.q ? `${state.total}개 검색 / ` : ''}{state.allTotal}개 전체
                                </Col>
                                <Col sm="8">
                                  <div className="text-sm-end d-flex gap-3 justify-content-end">
                                    <UncontrolledButtonDropdown>
                                      <DropdownToggle className="dropdown-toggle">
                                        {searchState.max}개 보기 <i className="mdi mdi-chevron-down" />
                                      </DropdownToggle>
                                      <DropdownMenu>
                                        {pagingList.map((item) => (
                                          <DropdownItem
                                            key={`pageSize_${item.max}`}
                                            onClick={() => changePageSize(item.max)}
                                            active={searchState.max === item.max}
                                          >
                                            {item.max}개 보기
                                          </DropdownItem>
                                        ))}
                                      </DropdownMenu>
                                    </UncontrolledButtonDropdown>
                                    <Button color="danger" className="font-16 btn-block btn btn-primary" onClick={handleDeleteList}>
                                      삭제
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={keyField}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                      selectRow={{
                                        mode: 'checkbox',
                                        selected: state.selected,
                                        onSelect: (row: any, isSelected: boolean, rowIndex: number, e: any) => {
                                          console.log('row:', row)

                                          if (isSelected) {
                                            setState((prev: any) => {
                                              return { ...prev, selected: [...prev.selected, row.id] }
                                            })
                                          } else {
                                            setState((prev: any) => {
                                              return { ...prev, selected: prev.selected.filter((item: any) => item !== row.id) }
                                            })
                                          }
                                        },
                                        onSelectAll: (isSelected: boolean, rows: any[]) => {
                                          const ids = rows.map((r) => r.id)
                                          if (isSelected) {
                                            setState((prev: any) => {
                                              return { ...prev, selected: ids }
                                            })
                                          } else {
                                            setState((prev: any) => {
                                              return { ...prev, selected: [] }
                                            })
                                          }
                                        },
                                      }}
                                      defaultSorted={defaultSorted}
                                      classes={'table align-middle table-nowrap table-hover'}
                                      bordered={false}
                                      striped={false}
                                      responsive
                                      ref={node}
                                      remote
                                      onTableChange={onTableChange}
                                      noDataIndication={<div className="text-center py-4">조회 결과 없음</div>}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="pagination pagination-rounded justify-content-end mb-2">
                                  <PaginationListStandalone {...paginationProps} />
                                </Col>
                              </Row>
                            </>
                          )}
                        </ToolkitProvider>
                      )
                    }}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <Modal isOpen={isModalOpen} toggle={toggleModal} size="lg">
        <ModalHeader toggle={toggleModal} tag="h4">
          {'카카오 알림톡 발송 내역 상세조회'}
        </ModalHeader>
        <ModalBody>
          <SentListDetail />
        </ModalBody>
      </Modal>

      {alert}
    </>
  )
}

export default SentList
