import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation, createSearchParams, useNavigate } from 'react-router-dom'
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  FormFeedback,
  Table,
} from 'reactstrap'
import toastr from "toastr";
import { useForm, Controller } from 'react-hook-form'
import Select from 'react-select'
import { APIClient } from '../../lib/apiHelper'
import moment from 'moment'
import { templateTypeList } from '../../constants/allim'

interface TemplateFormProps {
  selectedId: number | null
  onClose: (reload: boolean) => void
}

const buttonTypeList = [{ name: '페이지 링크', code: 'WEB_URL' }]

const TemplateForm = ({ selectedId, onClose }: TemplateFormProps) => {
  const navigate = useNavigate()
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm()

  const [state, setState] = useState({
    kakaoGroupCategoryList: [] as any,
    kakaoCategoryList: [] as any,
    template: {
      buttons: [] as any
    } as any,
  })

  const formOption = {
    type: register('type', { required: true }),
    name: register('name', { required: true }),
    content: register('content', { required: true }),
    kakaoGroupCategory: register('kakaoGroupCategory', { required: false }),
    kakaoCategory: register('kakaoCategory', { required: false }),
    secureTemplate: register('secureTemplate', { required: false }),
  }

  useEffect(() => {
    if (selectedId) {
      console.log('selectedId:', selectedId)
      loadDetail(selectedId)
    } else {
      console.log('selectedId:', null)
      clearForm()
    }
  }, [selectedId])

  const loadDetail = async (id: number) => {
    try {
      const { data: result } = await APIClient.get(`/allimtalk/template/${id}`)

      if (result.result) {
        const template = result.data.template
  
        setState({
          ...state,
          template,
        })
  
        //set form data
        const values = {
          type: template.type,
          name: template.name,
          content: template.content,
  
          kakaoGroupCategory: template.kakaoGroupCategory,
          kakaoCategory: template.kakaoCategory,
          secureTemplate: template.secureTemplate,
        }
  
        console.log('values:', values)
  
        reset(values)
      }
    } catch (error: any) {
      toastr.error(error.message)
    }
  }

  const clearForm = () => {
    setState((prev: any) => {
      return {
        ...prev,
        template: {
          buttons: []
        }
      }
    })
    reset({
      type: templateTypeList[0].code,
      name: '',
      content: '',

      kakaoGroupCategory: '',
      kakaoCategory: '',
      secureTemplate: false,
    })
  }

  const onSubmit = async (data: any) => {
    console.log('onSubmit', data)

    const params = {
      type: data.type,
      name: data.name,
      content: data.content,

      kakaoGroupCategory: data.kakaoGroupCategory,
      kakaoCategory: data.kakaoCategory,
      secureTemplate: data.secureTemplate,
      buttons: state.template.buttons,
    }

    console.log('params', params)

    if (!state.template.id) {
      try {
        const { data: result } = await APIClient.post(`/allimtalk/template`, params)

        if (result.result) {
          toastr.success('템플릿이 등록 되었습니다')
  
          clearForm()
          onClose(true)
        }
      } catch (error: any) {
        toastr.error(error.message)
      }
      
    } else {
      try {
        const { data: result } = await APIClient.put(`/allimtalk/template/${state.template.id}`, params)

        if (result.result) {
          toastr.success('템플릿이 수정 되었습니다')
  
          clearForm()
          onClose(true)
        }
      } catch (error: any) {
        toastr.error(error.message)
      }
    }
  }

  const onError = (d: any) => {
    console.error('error', d)
  }

  const addButton = () => {
    setState((prev: any) => {
      return {
        ...prev,
        template: {
          ...prev.template,
          buttons: [...prev.template.buttons, {buttonType: 'WEB_URL', buttonName: '', buttonUrl: ''}]
        }
      }
    })
  }

  const removeButton = (selectedIndex: number) => {
    const buttons = state.template.buttons.filter((btn: any, index: number) => index !== selectedIndex)

    setState((prev: any) => {
      return {
        ...prev,
        template: {
          ...prev.template,
          buttons: buttons
        }
      }
    })
  }

  const handleButtonChange = (e: any, selectedIndex: number, name: string) => {
    const val = e.target.value

    console.log('handeButtonChange val:', val)

    const buttons = state.template.buttons.map((btn: any, index: number) => {
      if (index === selectedIndex) {
        return {
          ...btn,
          [name]: val
        }
      }

      return btn
    })

    setState((prev: any) => {
      return {
        ...prev,
        template: {
          ...prev.template,
          buttons: buttons
        }
      }
    })
  }

  return (
    <>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <Form onSubmit={handleSubmit(onSubmit, onError)}>
                <FormGroup row>
                  <Label sm={2}>템플릿 구분</Label>
                  <Col sm={5}>
                    <Input
                      name="type"
                      type="select"
                      onChange={formOption.type.onChange}
                      onBlur={formOption.type.onBlur}
                      innerRef={formOption.type.ref}
                      invalid={!!errors.type}
                    >
                      {templateTypeList.map((item) => (
                        <option key={`templateType_${item.code}`} value={item.code}>
                          {item.name}
                        </option>
                      ))}
                    </Input>
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Label sm={2}>템플릿 명</Label>
                  <Col sm={5}>
                    <Input
                      name="name"
                      type="text"
                      onChange={formOption.name.onChange}
                      onBlur={formOption.name.onBlur}
                      innerRef={formOption.name.ref}
                      invalid={!!errors.name}
                    />
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Label sm={2}>템플릿 내용</Label>
                  <Col sm={10}>
                    <FormGroup row>
                      <Label sm={4}>템플릿 카테고리 설정</Label>
                      <Col sm={4}>
                        <Input
                          name="kakaoGroupCategory"
                          type="select"
                          bsSize="sm"
                          onChange={formOption.kakaoGroupCategory.onChange}
                          onBlur={formOption.kakaoGroupCategory.onBlur}
                          innerRef={formOption.kakaoGroupCategory.ref}
                          invalid={!!errors.kakaoGroupCategory}
                        >
                          {state.kakaoGroupCategoryList.map((item: any) => (
                            <option key={`templateType_${item.code}`} value={item.code}>
                              {item.name}
                            </option>
                          ))}
                        </Input>
                      </Col>

                      <Col sm={4}>
                        <Input
                          name="kakaoCategory"
                          type="select"
                          bsSize="sm"
                          onChange={formOption.kakaoCategory.onChange}
                          onBlur={formOption.kakaoCategory.onBlur}
                          innerRef={formOption.kakaoCategory.ref}
                          invalid={!!errors.kakaoCategory}
                        >
                          {state.kakaoCategoryList.map((item: any) => (
                            <option key={`templateType_${item.code}`} value={item.code}>
                              {item.name}
                            </option>
                          ))}
                        </Input>
                      </Col>
                    </FormGroup>

                    <Row className="mb-2">
                      <Col sm={9} className="pt-1">
                        <FormText>* 템플릿 카테고리는 카카오에서 사용하는 템플릿 구분 값으로, 미설정 시 '기타'로 등록됨</FormText>
                      </Col>
                      <Col sm={3}>
                        <Button type="button" color="info" className="float-end" size="sm">
                          치환코드 닫기
                        </Button>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm={6}>
                        <div className="table-responsive">
                          <Table className="table mb-0">
                            <thead className="table-dark">
                              <tr>
                                <th className="text-center py-1">알림톡</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td className="p-0">
                                  <Input
                                    name="content"
                                    type="textarea"
                                    rows="6"
                                    onChange={formOption.content.onChange}
                                    onBlur={formOption.content.onBlur}
                                    innerRef={formOption.content.ref}
                                    invalid={!!errors.content}
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                        <div className="text-secondary py-2" style={{ fontSize: 10 }}>
                          0/1000자
                        </div>

                        <FormText>* 템플릿 내용은 URL을 포함해 최대 1000자까지 입력 가능</FormText>
                      </Col>
                      <Col sm={6}>
                        <div className="table-responsive">
                          <Table className="table table-bordered mb-0" size="sm">
                            <thead className="table-dark">
                              <tr>
                                <th className="text-center">치환코드</th>
                                <th className="text-center">설명</th>
                                <th className="text-center">삽입</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>#orderNo</td>
                                <td>주문번호</td>
                                <td>
                                  <Button type="button" color="secondary" size="sm" outline>
                                    삽입
                                  </Button>
                                </td>
                              </tr>
                              <tr>
                                <td>#orderNo</td>
                                <td>주문번호</td>
                                <td>
                                  <Button type="button" color="secondary" size="sm" outline>
                                    삽입
                                  </Button>
                                </td>
                              </tr>
                              <tr>
                                <td>#orderNo</td>
                                <td>주문번호</td>
                                <td>
                                  <Button type="button" color="secondary" size="sm" outline>
                                    삽입
                                  </Button>
                                </td>
                              </tr>
                              <tr>
                                <td>#orderNo</td>
                                <td>주문번호</td>
                                <td>
                                  <Button type="button" color="secondary" size="sm" outline>
                                    삽입
                                  </Button>
                                </td>
                              </tr>
                              <tr>
                                <td>#orderNo</td>
                                <td>주문번호</td>
                                <td>
                                  <Button type="button" color="secondary" size="sm" outline>
                                    삽입
                                  </Button>
                                </td>
                              </tr>
                              <tr>
                                <td>#orderNo</td>
                                <td>주문번호</td>
                                <td>
                                  <Button type="button" color="secondary" size="sm" outline>
                                    삽입
                                  </Button>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Label sm={2}>보안 템플릿 설정</Label>
                  <Col sm={10}>
                    <FormGroup check>
                      <Input
                        name="secureTemplate"
                        type="checkbox"
                        onChange={formOption.secureTemplate.onChange}
                        onBlur={formOption.secureTemplate.onBlur}
                        innerRef={formOption.secureTemplate.ref}
                        invalid={!!errors.secureTemplate}
                      />
                      <Label>보안 템플릿 사용</Label>
                    </FormGroup>

                    <FormText>템플릿 내용에 인증번호, 비밀번호 등의 내용이 포함된 경우 필수 사용</FormText>
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Label sm={2} className="d-flex flex-column align-items-center justify-content-center">
                    <div>버튼</div>
                    <Button type="button" size="sm" color="secondary" className="d-block mt-2 text-center" outline onClick={addButton}>
                      추가
                    </Button>
                  </Label>
                  <Col sm={10}>
                    <div className="table-responsive">
                      <Table className="table table-bordered mb-0" size="sm">
                        <thead className="table-dark">
                          <tr>
                            <th className="text-center" style={{ width: 50 }}>
                              순서
                            </th>
                            <th className="text-center">버튼타입</th>
                            <th className="text-center" style={{ width: 220 }}>
                              버튼명
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {state.template.buttons.map((btn: any, index: number) => {
                            return (
                              <tr key={`template_button_${index}`}>
                                <td className="text-center">{index + 1}</td>
                                <td>
                                  <div>
                                    <Input name="buttonType" type="select" bsSize="sm" className="mb-2" style={{ width: 150 }} value={btn.buttonType} onChange={e => handleButtonChange(e, index, 'buttonType')}>
                                      {buttonTypeList.map((item) => (
                                        <option key={`buttonType_${index}_${item.code}`} value={item.code}>
                                          {item.name}
                                        </option>
                                      ))}
                                    </Input>
                                    <Input bsSize="sm" name="buttonUrl" value={btn.buttonUrl} onChange={e => handleButtonChange(e, index, 'buttonUrl')}/>
                                  </div>
                                </td>
                                <td>
                                  <div className="d-flex align-items-center gap-2">
                                    <Input name="buttonName" type="text" bsSize="sm" style={{ width: 150 }} value={btn.buttonName} onChange={e => handleButtonChange(e, index, 'buttonName')} />
                                    <Button type="button" color="secondary" size="sm" outline onClick={(e) => removeButton(index)}>
                                      삭제
                                    </Button>
                                  </div>
                                </td>
                              </tr>
                            )
                          })}

                          {state.template.buttons.length === 0 && (
                            <tr>
                              <td colSpan={3} className="text-center">
                                버튼이 없습니다.
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                </FormGroup>

                <Row>
                  <Col>
                    <FormText>모든 템플릿 등록 후 카카오에서 검수 완료 후 사용가능</FormText>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div className="d-flex gap-2 justify-content-center">
                      <Button
                        color="secondary"
                        onClick={() => {
                          clearForm()
                          onClose(true)
                        }}
                      >
                        최소
                      </Button>
                      <Button type="submit" color="primary">
                        저장
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  )
}


export default TemplateForm
