import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation, createSearchParams, useNavigate, useParams } from 'react-router-dom'
import MetaTags from 'react-meta-tags'
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  FormFeedback,
  Alert,
} from 'reactstrap'
import toastr from "toastr";
import { ContentState, convertToRaw, EditorState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { useForm } from 'react-hook-form'
import env from "../../lib/env"
import { APIClient } from '../../lib/apiHelper'
import Breadcrumbs from '../../components/Common/Breadcrumb'

const QuestionForm = () => {
  const navigate = useNavigate()
  const locationParams = useParams()

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm()
  const [state, setState] = useState({
    question: null as any,
    questionCategoryList: [] as any[],
    editorState: EditorState.createEmpty(),
  })

  const [formError, setFormError] = useState('')

  const formOption = {
    userName: register('userName', { required: true }),
    userEmail: register('userEmail', { required: true }),
    questionCategoryId: register('questionCategoryId', { required: true }),
    title: register('title', { required: true }),
    files: register('files', { required: false }),
  }

  useEffect(() => {
    loadFormData()
  }, [])

  useEffect(() => {
    if (locationParams.questionId) {
      loadDetail(locationParams.questionId)
    }
  }, [locationParams])

  const loadFormData = async () => {
    try {
      const { data: result } = await APIClient.get(`/board/question/category`)

      if (result.result) {
        console.log('category list:', result.data.questionCategoryList)
        setState((prev: any) => {
          return {
            ...prev,
            questionCategoryList: result.data.questionCategoryList,
          }
        })

        if (result.data.questionCategoryList) {
          setValue('questionCategoryId', result.data.questionCategoryList[0].id)
        }
        
      }
    } catch (error: any) {
      toastr.error(error.message)
    }
  }

  const loadDetail = async (id: string) => {
    try {
      const { data: result } = await APIClient.get(`/board/question/${id}`)

      if (result.result) {
        const question = result.data.question
  
        console.log('question:', question)
  
        const contentBlock = htmlToDraft(question.content)
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
        const editorState = EditorState.createWithContent(contentState)
  
        setState((prev: any) => {
          return {
            ...prev,
            question: question,
            editorState
          }
        })
  
        const values = {
          userName: question.user?.name,
          userEmail: question.userEmail,
          questionCategoryId: question.questionCategoryId,
  
          title: question.title,
          //content: question.content,
        }
  
        reset(values)
      }
    } catch (error: any) {
      toastr.error(error.message)
    }
  }

  const onSubmit = async (data: any) => {
    console.log('onSubmit', data)

    const contentState = state.editorState.getCurrentContent()

    if (!contentState.hasText()) {
      setFormError('내용 입력해주세요')

      return
    } else {
      setFormError('')
    }

    const content = draftToHtml(convertToRaw(contentState))

    console.log('content:', content)

    const params = {
      userName: data.userName,
      userEmail: data.userEmail,
      questionCategoryId: data.questionCategoryId,
      title: data.title,
      content: content,
      files: data.files,
    }

    const formData = new FormData()
    formData.append('userName', data.userName)
    formData.append('userEmail', data.userEmail)
    formData.append('questionCategoryId', data.questionCategoryId)
    formData.append('title', data.title)
    formData.append('content', content)

    if (state.question?.files) {
      for (let file of state.question?.files) {
        console.log('file:', file)
        formData.append('files', JSON.stringify(file))
      }
    }
    

    if (data.files) {
      for (let file of data.files) {
        console.log('file:', file)
        formData.append('uploadFiles', file)
      }
    }

    if (!locationParams.questionId) {
      try {
        const { data: result } = await APIClient.post(`/board/question`, formData, {
          'Content-Type': 'multipart/form-data'
        })

        if (result.result) {
          toastr.success('문의들이 등록 되었습니다')

          navigate('/board/question')
        }
      } catch (error: any) {
        toastr.error(error.message)
      }
    } else {
      try {
        const { data: result } = await APIClient.put(`/board/question/${locationParams.questionId}`, formData, {
          'Content-Type': 'multipart/form-data'
        })

        if (result.result) {
          toastr.success('문의들이 수정 되었습니다')

          navigate('/board/question')
        }
      } catch (error: any) {
        toastr.error(error.message)
      }
    }
  }

  const onError = (d: any) => {
    console.error('error', d)
  }

  const onEditorStateChange = (editorState: any) => {
    setState((prev: any) => {
      return {
        ...prev,
        editorState,
      }
    })
  }

  const handleDeleteFile = (url: string) => {
    console.log('url:', url)
    setState((prev: any) => {
      return {
        ...prev,
        question: {
          ...prev.question,
          files: prev.question.files.filter((file: any) => file.url !== url)
        }
      }
    })
  }

  const title = locationParams.questionId ? '이용문의 수정' : '이용문의 등록'

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>{title}</title>
        </MetaTags>

        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="이용문의" breadcrumbItem={title} />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  {formError && (
                    <Alert color="danger" role="alert">
                      {formError}
                    </Alert>
                  )}
                  
                  <Form onSubmit={handleSubmit(onSubmit, onError)}>
                    <Row>
                      <Col xs={12}>
                        <FormGroup row>
                          <Label sm={2}>*작성자</Label>
                          <Col sm={10}>
                            <Input
                              name="userName"
                              readOnly={state.question ? true : false}
                              onChange={formOption.userName.onChange}
                              onBlur={formOption.userName.onBlur}
                              innerRef={formOption.userName.ref}
                              invalid={!!errors.userName}
                            />
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Label sm={2}>*이메일</Label>
                          <Col sm={10}>
                            <Input
                              name="userEmail"
                              readOnly={state.question ? true : false}
                              onChange={formOption.userEmail.onChange}
                              onBlur={formOption.userEmail.onBlur}
                              innerRef={formOption.userEmail.ref}
                              invalid={!!errors.userEmail}
                            />
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Label sm={2}>*문의유형</Label>
                          <Col sm={10}>
                            <Input
                              name="questionCategoryId"
                              type="select"
                              onChange={formOption.questionCategoryId.onChange}
                              onBlur={formOption.questionCategoryId.onBlur}
                              innerRef={formOption.questionCategoryId.ref}
                              invalid={!!errors.questionCategoryId}
                            >
                              {
                                state.questionCategoryList.map(item => <option key={`question_category_${item.id}`} value={item.id}>{item.name}</option>)
                              }
                              </Input>
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Label sm={2}>*제목</Label>
                          <Col sm={10}>
                            <Input
                              name="title"
                              onChange={formOption.title.onChange}
                              onBlur={formOption.title.onBlur}
                              innerRef={formOption.title.ref}
                              invalid={!!errors.title}
                            />
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Label sm={2}>*내용</Label>
                          <Col sm={10}>
                          <Editor
                              toolbarClassName="toolbarClassName"
                              wrapperClassName="wrapperClassName"
                              editorClassName="editorClassName"
                              placeholder="내용 입력..."
                              localization={{
                                locale: 'ko',
                              }}
                              editorState={state.editorState}
                              onEditorStateChange={onEditorStateChange}
                            />
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Label sm={2}>첨부파일</Label>
                          <Col sm={5}>
                          <div className="mb-3">
                              {state.question?.files?.map((file: any, index: number) => {
                                return (
                                <div className="mb-2 d-flex align-items-center gap-2" key={`attach_file_${index}`}>
                                  <a href={`${env.SITE_URL}${file.url}`} target="_blank">{file.fileName}</a>
                                  <Link className="text-muted" to="#">
                                    <i
                                      className="mdi mdi-delete font-size-18"
                                      onClick={(e) => {
                                        e.preventDefault()
                                        handleDeleteFile(file.url)
                                      }}
                                    ></i>
                                  </Link>
                                </div>
                                )
                              })}
                            </div>

                            <Input
                              name="files"
                              id="formFile"
                              type="file"
                              multiple
                              onChange={formOption.files.onChange}
                              onBlur={formOption.files.onBlur}
                              innerRef={formOption.files.ref}
                              invalid={!!errors.files}
                            />
                          </Col>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <div className="d-flex gap-2 justify-content-center">
                          <Button
                            color="secondary"
                            onClick={() => {
                              navigate(-1)
                            }}
                          >
                            최소
                          </Button>
                          <Button type="submit" color="primary">
                            저장
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default QuestionForm
