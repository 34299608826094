import React, { Component, Fragment } from "react"
import { Outlet, useLocation } from "react-router-dom"

type NonAuthLayoutProps = {
  // children: object
  // location: object
}

const capitalizeFirstLetter = (string: string) => {
  return string.charAt(1).toUpperCase() + string.slice(2)
}

const NonAuthLayout = (props:NonAuthLayoutProps) => {
  const state = {}
  
  return <><Outlet /></>
}



export default NonAuthLayout
