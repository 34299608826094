import React, { Component, useState } from "react";

import { Row, Col } from "reactstrap";


// import ReactDrawer from 'react-drawer';
// import "react-drawer/lib/react-drawer.css";

import { Link } from "react-router-dom";

// Reactstrap
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";

// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
import RightSidebar from "../CommonForBoth/RightSidebar";

import megamenuImg from "../../assets/images/megamenu-img.png";

// import images
import github from "../../assets/images/brands/github.png";
import bitbucket from "../../assets/images/brands/bitbucket.png";
import dribbble from "../../assets/images/brands/dribbble.png";
import dropbox from "../../assets/images/brands/dropbox.png";
import mail_chimp from "../../assets/images/brands/mail_chimp.png";
import slack from "../../assets/images/brands/slack.png";

import logo from "../../assets/images/logo.svg";
import logoLightSvg from "../../assets/images/logo-light.svg";

//i18n
import { useTranslation } from "react-i18next";

// Redux Store
//import { toggleRightSidebar } from "../../store/actions"


//const ReactDrawer = require("react-drawer");

type HeaderType = {
  toggleMenuCallback: any;
  // toggleRightSidebar: any;
};

const Header = (props: HeaderType) => {
  const { t } = useTranslation();
  const [state, setState] = useState({
    isSearch: false,
    open: false,
    position: "right",
    megaMenuDrp: false,
    socialDrp: false,
  });

  /**
   * Toggle sidebar
   */
  const toggleMenu = () => {
    props.toggleMenuCallback();
  };
  /**
   * Right sidebar drawer
   * */

  const toggleRightDrawer = () => {
    setState({ ...state, position: "right", open: !state.open });
  };

  const closeDrawer = () => {
    setState({ ...state, open: false });
  };
  const onDrawerClose = () => {
    setState({ ...state, open: false });
  };

  /**
   * Toggles the sidebar
   */
  const toggleRightbar = () => {
    // props.toggleRightSidebar();
  };

  const toggleFullscreen = () => {
    // if (
    //   !document.fullscreenElement &&
    //   /* alternative standard method */ !document.mozFullScreenElement &&
    //   !document.webkitFullscreenElement
    // ) {
    //   // current working methods
    //   if (document.documentElement.requestFullscreen) {
    //     document.documentElement.requestFullscreen()
    //   } else if (document.documentElement.mozRequestFullScreen) {
    //     document.documentElement.mozRequestFullScreen()
    //   } else if (document.documentElement.webkitRequestFullscreen) {
    //     document.documentElement.webkitRequestFullscreen(
    //       Element.ALLOW_KEYBOARD_INPUT
    //     )
    //   }
    // } else {
    //   if (document.cancelFullScreen) {
    //     document.cancelFullScreen()
    //   } else if (document.mozCancelFullScreen) {
    //     document.mozCancelFullScreen()
    //   } else if (document.webkitCancelFullScreen) {
    //     document.webkitCancelFullScreen()
    //   }
    // }
  };

  return (
    <>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box d-lg-none d-md-block">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logo} alt="" height="22" />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logoLightSvg} alt="" height="22" />
                </span>
              </Link>
            </div>

            <button
              type="button"
              onClick={toggleMenu}
              className="btn btn-sm px-3 font-size-16 header-item"
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars"></i>
            </button>

          </div>
          <div className="d-flex">
            {/* <NotificationDropdown /> */}
            <ProfileMenu />

            <div
              onClick={toggleRightDrawer}
              className="dropdown d-inline-block"
            >
              <button
                type="button"
                className="btn header-item noti-icon right-bar-toggle"
              >
                <i className="bx bx-cog bx-spin"></i>
              </button>
            </div>
          </div>
        </div>
      </header>
      {/* <ReactDrawer
        open={state.open}
        position={state.position}
        onClose={onDrawerClose}
      >
        <RightSidebar
          onClose={onDrawerClose}
          changeLayout={(v: any) => {}}
          changeSidebarTheme={(v: any) => {}}
          changeSidebarThemeImage={(v: any) => {}}
          changeSidebarType={(v: any) => {}}
          changeLayoutWidth={(v: any) => {}}
          changeTopbarTheme={(v: any) => {}}
          changePreloader={(v: any) => {}}
          showRightSidebarAction={() => {}}
        />
      </ReactDrawer> */}
    </>
  );
};

export default Header;
